import { Text } from "@centrate-io/barn";
import { Link } from "react-router-dom";

export const getColumns = (category) => {
  return [
    {
      title: (
        <div className="column-name">
          Loan Number
        </div>
      ),
      dataIndex: "loan_number",
      key: "loan_number",
      width: 280,
      render: (name, data) => (
        <Link to={`/deal/${data.deal.id}/loan/${data.id}`} className="application-title">
          <Text className="doc-name">
            <b>{data.loan_number}</b>
          </Text>
        </Link>
      ),
    }
  ];
};
