import React, { useState } from "react";
import { Tabs } from "../../_styleguide";
import { IconReport } from "@tabler/icons-react";
import { useNavigate, useParams, Link } from "react-router-dom";
import { Header, Text } from "@centrate-io/barn";
import "./InfoRequests.scss";

function InfoRequests(props) {
    const navigate = useNavigate();
    const params = useParams();
    const [tab, setTab] = useState(params?.tab) || "sample-tab";
    const deal_id = params.id;
  
    let items = [
      {
        key: "sample-tab",
        label: (
          <div className="tab-name">
            <IconReport />
            Sample Tab 1
          </div>
        ),
        children: <></>,
      },
      {
        key: "sample-tab-2",
        label: (
          <div className="tab-name">
            <IconReport />
            Sample Tab 2
          </div>
        ),
        children: <></>,
      },
      {
        key: "sample-tab-3",
        label: (
          <div className="tab-name">
            <IconReport />
            Sample Tab 3
          </div>
        ),
        children: <></>,
      }
    ];
  
    return (
      <div className="admin-tabs app-exception-report">
        <div className="admin-header">
          <div className="crumbs" >
            <Header type="display" size="5">
              Info Requests
            </Header>
          </div>
        </div>
        <Tabs
          onChange={(key) => {
            navigate(`/deal/${deal_id}/info-requests/${key}`);
            setTab(key);
          }}
          activeKey={tab}
          items={items}
          className={tab}
          destroyInactiveTabPane={true}
        />
      </div>
    );
  }
  
  export default InfoRequests;