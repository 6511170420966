import React, { useState, useEffect } from "react";
import { useParams } from "react-router-dom";
import { Search, Table } from "../../../../_styleguide";
import { Button, message } from "@centrate-io/barn";
import { getColumns } from "./Columns";
import axios from "../../../../_helpers/axios";
import Import from "../../../_components/Import/Import";
import { IconTableImport } from "@tabler/icons-react";
import { loanColumns } from "./_constants/loan";
import "./LoansTable.scss";

const defaultParams = {
  results: 20,
  page: 1,
  sortField: undefined,
  sortOrder: undefined,
};

function LoansTable(props) {
  const urlParams = useParams();
  const [state, setState] = useState({
    loading: true,
    data: [],
    pagination: { pageSize: 20, showSizeChanger: false },
  });
  const [importModal, setImportModal] = useState(false);
  const [search, setSearch] = useState();
  const [page, setPage] = useState(1);
  const columns = getColumns(urlParams.id);

  // Load Applications
  const fetchLoans = async (params = defaultParams) => {
    try {
      const response = await axios.get(
        `${process.env.REACT_APP_API_URL}/loans`,
        {
          params: {
            ...params,
            search: search,
            deal_id: urlParams.id,
          },
        },
      );
      const pagination = { ...state.pagination };
      pagination.total = response?.data?.pageTotal;
      pagination.totalAll = response?.data?.total;
      setPage(params?.page || 1);
      setState({
        loading: false,
        data: response?.data?.data || [],
        pagination: pagination,
      });
    } catch (error) {
      message.error("Failed to fetch loans");
      setState({
        ...state,
        loading: false,
      });
    }
  };

  const importLoans = async (loans) => {
    const updatedLoans = loans.map((loan) => {
      return { ...loan, deal_id: urlParams.id };
    });
    try {
      const response = await axios.post(
        `${process.env.REACT_APP_API_URL}/loans/add`,
        updatedLoans,
      );
      const successfulLoans = (response?.data || [])?.filter((d) => {
        return d.success === true;
      });
      setImportModal(false);
      message.success(
        `Successfully imported ${successfulLoans?.length}/${loans?.length} loans`,
      );
    } catch (error) {
      message.error(error, "Failed to import loans");
    }
  };

  const handleTableChange = (pagination, filters) => {
    const pager = { ...state.pagination };
    pager.current = pagination.current;
    setState({ pagination: pager });
    fetchLoans({
      results: pagination.pageSize,
      page: pagination.current,
    });
  };

  useEffect(() => {
    fetchLoans();
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  const startResults = (page - 1) * defaultParams?.results + 1;
  const endResults = startResults + state?.data?.length - 1;

  return (
    <div className="loans-tab">
      {importModal ? (
        <Import
          removeModal={() => setImportModal(false)}
          apiFunction={importLoans}
          columns={loanColumns}
          mappingType="loan"
        />
      ) : null}
      <div className="admin-search">
        <Button
          type="secondary"
          onClick={() => setImportModal(true)}
          className="boxed"
        >
          <IconTableImport />
          Import Loans
        </Button>
        <Search
          placeholder={`Search loans...`}
          value={search}
          onChange={(e) => setSearch(e.target.value)}
          onSearch={() => {
            fetchLoans({ ...defaultParams, search });
          }}
          style={{ width: 304 }}
        />
      </div>
      <div className="admin-table loans-table">
        <Table
          size="small"
          columns={columns}
          loading={state.loading}
          pagination={state.pagination}
          onChange={handleTableChange}
          rowKey={(record) => record.id}
          dataSource={state?.data}
          showSorterTooltip={false}
          scroll={{
            y: "calc(100vh - 247px)",
            x: "max-content",
          }}
          locale={{
            emptyText: state.loading ? `Loading loans...` : `No loans found`,
          }}
        />
      </div>
      {state?.data?.length > 0 && (
        <div className="admin-pagination">
          Showing {startResults}-{endResults} of {state?.pagination?.total || 0}{" "}
        </div>
      )}
    </div>
  );
}

export default LoansTable;
