import React, { useState, useContext } from "react";
import { Input, Button, message } from "@centrate-io/barn";
import { useNavigate, Link } from "react-router-dom";
import CamLogo from "../../_assets/logos/cam.png";
import { AuthContext } from "../../_contexts/AuthContext";
import "./Login.scss";

function Login(props) {
  const { login } = useContext(AuthContext);
  const navigate = useNavigate();
  const [email, setEmail] = useState();
  const [password, setPassword] = useState();
  const [loading, setLoading] = useState(false);

  // Submit login and receive token
  const handleSubmit = async () => {
    setLoading(true);
    const loginData = await login(email, password);
    if (loginData.success) {
      message.success("Login successful");
      navigate("/");
    } else {
      message.error("Invalid login, try again");
    }
    setLoading(false);
  };

  return (
    <React.Fragment>
      <div className="auth-login">
        <div className="auth-info">
          <img className="login-logo" src={CamLogo} alt="PayPathAI" />
        </div>
        <div className="login-form">
          <div className="line">
            <Input.Label>Email</Input.Label>
            <Input
              value={email}
              onChange={(e) => setEmail(e.target.value)}
              size="large"
            />
          </div>

          <div className="line">
            <Input.Label>Password</Input.Label>
            <Input
              type="password"
              value={password}
              onChange={(e) => setPassword(e.target.value)}
              size="large"
            />
          </div>

          <div className="forgot">
            <Link className="pp-link" to="/register">
              Register Account
            </Link>
          </div>

          <Button
            loading={loading}
            type="secondary"
            onClick={handleSubmit}
            size="large"
            block
          >
            Login
          </Button>
        </div>
      </div>
    </React.Fragment>
  );
}

export default Login;
