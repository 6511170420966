import React, { useState } from "react";
import { useParams } from "react-router-dom";
import axios from "../../_helpers/axios";
import { Link } from "react-router-dom";
import { Table, Typography, Space, Layout } from "antd";
import LoansTable from "./_components/LoansTable/LoansTable";
import { useDeals } from "../../_contexts/DealsContext";
const { Content } = Layout;
const { Title, Text } = Typography;

const Deal = () => {
  const { id } = useParams();
  const { setActiveDeal, activeDeal } = useDeals();

  if (!activeDeal) {
    return <div>Loading...</div>;
  }

  return (
    <Layout className="layout">
      <Content style={{ padding: "0 50px" }}>
        <Space direction="vertical" size="large" style={{ width: "100%" }}>
          <div
            style={{
              display: "flex",
              justifyContent: "flex-start",
              maxWidth: 550,
              alignItems: "flex-end",
              marginTop: 50,
              marginBottom: 10,
            }}
          >
            <Title level={3} style={{ margin: 0 }}>
              {activeDeal.name}
            </Title>

            <Link
              style={{ marginLeft: 20, paddingBottom: 4 }}
              to={`/edit-deal/${id}`}
            >
              Edit Deal
            </Link>
          </div>
        </Space>
        <Space direction="vertical" size="large" style={{ width: "100%" }}>
          <div style={{ display: "flex", justifyContent: "flex-start" }}>
            <Text strong type="secondary" style={{ marginRight: 15 }}>
              Status: {activeDeal.status}
            </Text>
            <Text strong type="secondary" style={{ marginRight: 15 }}>
              Insurer: {activeDeal.insurer.name}
            </Text>
            <Text strong type="secondary" style={{ marginRight: 15 }}>
              Servicer: {activeDeal.servicer.name}
            </Text>
            <Text strong type="secondary" style={{ marginRight: 15 }}>
              Date Created: {activeDeal.date_created}
            </Text>
            {activeDeal.date_closed && (
              <Text type="secondary" strong>
                Date Closed: {activeDeal.date_closed}
              </Text>
            )}
          </div>
        </Space>
        <Space direction="vertical" size="large" style={{ width: "100%" }}>
          <div style={{ display: "flex", justifyContent: "flex-start" }}>
            <Link
              to={`/deal/${id}/data-upload-hub`}
              style={{ marginRight: 15 }}
            >
              Data upload Hub
            </Link>
            <Link
              to={`/deal/${id}/summary-dashboard`}
              style={{ marginRight: 15 }}
            >
              Summary Dashboard
            </Link>
            <Link
              to={`/deal/${id}/secured-workflow`}
              style={{ marginRight: 15 }}
            >
              Secured Workflow
            </Link>
            <Link
              to={`/deal/${id}/exception-reporting`}
              style={{ marginRight: 15 }}
            >
              Exception reporting workflow
            </Link>
            <Link to={`/deal/${id}/comment-review`} style={{ marginRight: 15 }}>
              Comment Review
            </Link>
            <Link to={`/deal/${id}/pricing-module`} style={{ marginRight: 15 }}>
              Pricing Module
            </Link>
            <Link to={`/deal/${id}/add-to-sale`} style={{ marginRight: 15 }}>
              Add to sale
            </Link>
            <Link to={`/deal/${id}/info-requests`} style={{ marginRight: 15 }}>
              Info Requests
            </Link>
          </div>
        </Space>
        {activeDeal.tape_uploaded === "true" ? (
          <Space direction="vertical" size="large" style={{ width: "100%" }}>
            <Title level={4}>Tape</Title>
            <LoansTable deal_id={id} />
          </Space>
        ) : (
          <div>No tape uploaded</div>
        )}
      </Content>
    </Layout>
  );
};

export default Deal;
