import { useContext } from "react";
import { BrowserRouter } from "react-router-dom";
import Auth from "./Auth/Auth";
import AppRoutes from "./App/App";
import { AuthContext } from "./_contexts/AuthContext";

function App(props) {
  // Handle User & Login
  const { auth } = useContext(AuthContext);

  const authRoutes = <Auth />;
  const appRoutes = <AppRoutes />;

  // If not logged in, show auth else show app
  const user = auth?.user;
  let routes = null;
  if (user) {
    routes = appRoutes;
  } else {
    routes = authRoutes;
  }

  return <BrowserRouter>{routes}</BrowserRouter>;
}

export default App;
