import React, { Component } from "react";
import { Tabs as AntdTabs } from "antd";
import "./Tabs.scss";

class Tabs extends Component {
  render() {
    const props = { ...this.props };
    props.className = "pp-tabs " + (props.className || "");
    return <AntdTabs popupClassName="pp-tabs-popup" {...props} />;
  }
}

export default Tabs;
