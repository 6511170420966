import React, { useState } from "react";
import { Tabs } from "../../_styleguide";
import { IconReport } from "@tabler/icons-react";
import { useNavigate, useParams, Link } from "react-router-dom";
import { Header, Text } from "@centrate-io/barn";
import LeinReport from "./_components/LeinReport/LeinReport";
import DollarScrub from "./_components/DollarScrub/DollarScrub";
import TitleOrders from "./_components/TitleOrders/TitleOrders";
import ExceptionReports from "./_components/ExceptionReports/ExceptionReports";
import CrsPropertyData from "./_components/CrsPropertyData/CrsPropertyData";
import BulkZillow from "./_components/BulkZillow/BulkZillow";
import ServicerComments from "./_components/ServicerComments/ServicerComments";
import "./DataUploadHub.scss";

function DataUploadHub(props) {
    const navigate = useNavigate();
    const params = useParams();
    const [tab, setTab] = useState(params?.tab) || "lein-report";
    const deal_id = params.id;
  
    let items = [
      {
        key: "lein-report",
        label: (
          <div className="tab-name">
            <IconReport />
            Lein Report
          </div>
        ),
        children: <LeinReport/>,
      },
      {
        key: "dollar-scrub",
        label: (
          <div className="tab-name">
            <IconReport />
            Dollar Scrub
          </div>
        ),
        children: <DollarScrub/>,
      },
      {
        key: "title-orders",
        label: (
          <div className="tab-name">
            <IconReport />
            Title Orders
          </div>
        ),
        children: <TitleOrders/>,
      },
      {
        key: "bulk-zillow",
        label: (
          <div className="tab-name">
            <IconReport />
            Bulk Zillows
          </div>
        ),
        children: <BulkZillow/>,
      },
      {
        key: "crs-property-data",
        label: (
          <div className="tab-name">
            <IconReport />
            CRS Property Data Reports
          </div>
        ),
        children: <CrsPropertyData/>,
      },
      {
        key: "exception-reports",
        label: (
          <div className="tab-name">
            <IconReport />
            Exception Reports
          </div>
        ),
        children: <ExceptionReports/>,
      },
      {
        key: "servicer-comments",
        label: (
          <div className="tab-name">
            <IconReport />
            Servicer Comments
          </div>
        ),
        children: <ServicerComments/>,
      },
    ];
  
    return (
      <div className="admin-tabs app-data-hub">
        <div className="admin-header">
          <div className="crumbs" >
            <Header type="display" size="5">
              Data Upload Hub
            </Header>
          </div>
        </div>
        <Tabs
          onChange={(key) => {
            navigate(`/deal/${deal_id}/data-upload-hub/${key}`);
            setTab(key);
          }}
          activeKey={tab}
          items={items}
          className={tab}
          destroyInactiveTabPane={true}
        />
      </div>
    );
  }
  
  export default DataUploadHub;