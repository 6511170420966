import React, { useState, useEffect } from "react";
import { useLocation } from "react-router-dom";
import AppRoutes from "./_components/AppRoutes/AppRoutes";
import Company from "./_components/Company/Company";
import Deal from "./_components/Deal/Deal";
import { Button } from "@centrate-io/barn";
import { IconPlus } from "@tabler/icons-react";
import { Layout, Menu } from "antd";
import { useDeals } from "../_contexts/DealsContext";
import { getDealId, dealMenuItems } from "../_helpers/deal";
import { getSelectedKeys } from "../_helpers/routing";
import axios from "../_helpers/axios";
import "./App.scss";

const { Content, Sider } = Layout;

function App(props) {
  const location = useLocation();
  const [collapsed, setCollapsed] = useState(false);
  const { activeDeal, setActiveDeal } = useDeals();

  const fetchDeal = async (dealId) => {
    try {
      const response = await axios.get(
        `${process.env.REACT_APP_API_URL}/deals/${dealId}`,
      );
      setActiveDeal(response.data);
    } catch (error) {
      console.error("Failed to fetch deal", error);
    }
  };

  useEffect(() => {
    const dealId = getDealId();
    const isNewDeal = activeDeal?.id?.toString() !== dealId ? true : false;
    if (dealId && isNewDeal) {
      fetchDeal(dealId);
    } else if (!dealId) {
      setActiveDeal(null);
    }
  }, [location]);

  const items = dealMenuItems(activeDeal);
  const menuKeys = getSelectedKeys(location?.pathname);

  return (
    <Layout className={`layout-admin${activeDeal ? "" : " no-deal"}`} hasSider>
      <Company collapsed={collapsed} />
      {activeDeal ? (
        <Sider
          width={240}
          collapsible
          collapsed={collapsed}
          onCollapse={(value) => setCollapsed(value)}
        >
          <Deal collapsed={collapsed} deal={activeDeal} />
          <Menu
            theme="light"
            mode="inline"
            selectedKeys={menuKeys}
            items={[...items]}
          />
        </Sider>
      ) : null}
      <Layout className={`site-layout ${collapsed ? "collapsed" : ""}`}>
        <Content className="site-content">
          <div className="admin-body">
            <AppRoutes {...props} />
          </div>
        </Content>
      </Layout>
    </Layout>
  );
}

export default App;
