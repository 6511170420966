import React, { useState, useEffect } from "react";
import { Header, Button, message } from "@centrate-io/barn";
import { structure } from "./_helpers/form";
import {
  FormInput,
  InputNumber,
  InputLabel,
  Flex,
} from "../../../../_styleguide";
import axios from "../../../../_helpers/axios";
import { LoanType } from "../../../../_types/loan";

import "./LoanData.scss";

function LoanData(props) {
  const [form, setForm] = useState(undefined);
  const [savedForm, setSavedForm] = useState(undefined);
  const [saving, setSaving] = useState(false);

  const saveLoan = async () => {
    setSaving(true);
    try {
      const updatedLoan = {
        ...form,
      };
      const response = await axios.put(
        `${process.env.REACT_APP_API_URL}/loans/${form.id}`,
        updatedLoan,
      );
      if (response?.data?.success) {
        message.success("Loan successfully saved");
      } else {
        throw new Error("Error saving loan, try again");
      }
      setSaving(false);
    } catch (error) {
      setSaving(false);
      message.error("Failed to edit deal: " + (error?.message || error));
    }
  };

  useEffect(() => {
    const originalForm = { ...props.loan };
    setForm(originalForm);
    setSavedForm(JSON.stringify(originalForm));
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  // Update form by field and value
  const updateForm = (field, value) => {
    const editedform = { ...form };
    editedform[field] = value;
    setForm(editedform);
  };

  const isEdited = JSON.stringify(form) !== savedForm;

  return !form ? null : (
    <div className="loan-form">
      {Object.keys(structure).map((s) => {
        const categoryItems = structure[s];

        return (
          <div className="lf-cat" key={s}>
            <div className="admin-search">
              <Button
                loading={saving}
                onClick={saveLoan}
                className="boxed"
                disabled={!isEdited}
              >
                {saving ? "Saving Loan" : "Save Loan"}
              </Button>
            </div>
            <Header type="display" size="5">
              {s}
            </Header>
            <div className="lf-items">
              {categoryItems.map((c) => {
                const cVal = form[c.field];
                const cType = LoanType[c.field]?.Type || "String";
                return (
                  <Flex className="form-line" vertical={true} key={c?.label}>
                    <InputLabel htmlFor="delay">{c?.label}</InputLabel>
                    {cType === "String" ? (
                      <FormInput
                        onChange={(e) => updateForm(c?.field, e.target.value)}
                        value={cVal}
                        id="email"
                        placeholder="---"
                        disabled={c.disabled}
                        size="small"
                      />
                    ) : null}
                    {cType === "Percent" ? (
                      <InputNumber
                        controls={false}
                        precision={2}
                        value={parseFloat(cVal)}
                        onChange={(val) =>
                          updateForm(c?.field, parseFloat(val))
                        }
                        size="small"
                      />
                    ) : null}
                    {cType === "Number" ? (
                      <InputNumber
                        controls={false}
                        value={parseFloat(cVal)}
                        onChange={(val) =>
                          updateForm(c?.field, parseFloat(val))
                        }
                        size="small"
                        formatter={(value) => {
                          if (value === undefined || value === null) return "";
                          return `${Number(value)
                            .toFixed(2)
                            .replace(/\B(?=(\d{3})+(?!\d))/g, ",")}`;
                        }}
                        parser={(value) => value.replace(/\$\s?|(,*)/g, "")}
                        precision={2}
                      />
                    ) : null}
                    {cType === "Integer" ? (
                      <InputNumber
                        controls={false}
                        precision={0}
                        value={parseInt(cVal)}
                        onChange={(val) => updateForm(c?.field, parseInt(val))}
                        size="small"
                        formatter={(value) =>
                          `${value}`.replace(/\B(?=(\d{3})+(?!\d))/g, ",")
                        }
                        parser={(value) => value?.replace(/\$\s?|(,*)/g, "")}
                      />
                    ) : null}
                    {!["Integer", "Number", "Percent", "String"].includes(
                      cType,
                    ) ? (
                      <FormInput
                        onChange={(e) => updateForm(c?.field, e.target.value)}
                        value={cVal}
                        id="email"
                        placeholder="---"
                        disabled={c.disabled}
                        size="small"
                      />
                    ) : null}
                  </Flex>
                );
              })}
            </div>
          </div>
        );
      })}
    </div>
  );
}

export default LoanData;
