import React, { createContext, useState, useContext } from "react";
import axios from "../_helpers/axios";

export const DealsContext = createContext();

const DealsProvider = ({ children }) => {
  const [activeDeal, setActiveDeal] = useState();
  const [deals, setDeals] = useState([]);
  const apiUrl = process.env.REACT_APP_API_URL;

  const createDeal = async (deal) => {
    try {
      const response = await axios.post(`${apiUrl}/deals/add`, deal);
      setDeals([...deals, response.data]);
    } catch (error) {
      console.error("Failed to create deal", error);
    }
  };

  const editDeal = async (id, updatedDeal) => {
    try {
      const response = await axios.put(`${apiUrl}/deals/${id}`, updatedDeal);
      setDeals(deals.map((deal) => (deal.id === id ? response.data : deal)));
    } catch (error) {
      console.error("Failed to edit deal", error);
    }
  };

  return (
    <DealsContext.Provider
      value={{ deals, createDeal, editDeal, setActiveDeal, activeDeal }}
    >
      {children}
    </DealsContext.Provider>
  );
};

export const useDeals = () => {
  const context = useContext(DealsContext);

  if (!context) {
    throw new Error("useMyContext must be used within a MyProvider");
  }

  return context;
};

export default DealsProvider;
