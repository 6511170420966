import { Route, Routes } from "react-router-dom";
import Login from "./Login/Login";
import Register from "./Register/Register";
import "./Auth.scss";

function Auth(props) {
  return (
    <div className="auth-main">
      <div className="auth-box">
        <Routes>
          <Route exact path="/" element={<Login {...props} />} />
          <Route exact path="/register" element={<Register {...props} />} />
        </Routes>
      </div>
    </div>
  );
}

export default Auth;
