import { useState, useEffect } from "react";
import { Modal } from "@centrate-io/barn";
import Upload from "./_components/Upload/Upload";
import Mapping from "./_components/Mapping/Mapping";
import Workbook from "./_components/Workbook/Workbook";
import { getMappings } from "./_helpers/mapping";
import "./Import.scss";

function Import(props) {
  const [visible, setVisible] = useState(true);
  const [spreadsheet, setSpreadsheet] = useState();
  const [workbook, setWorkbook] = useState();
  const [dbMapping, setDbMapping] = useState();

  // Load Applications
  const fetchMapping = async () => {
    if (props.mappingType) {
      const mappingData = await getMappings(props.mappingType);
      if (mappingData?.length > 0) {
        let mappingMap = {};
        mappingData?.forEach((m) => {
          mappingMap[m.sheet_column] = m.table_column;
        });
        setDbMapping(mappingMap);
      }
    }
  };

  useEffect(() => {
    fetchMapping();
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  return (
    <Modal
      wrapClassName="import-modal"
      open={visible}
      title={null}
      footer={null}
      onCancel={() => setVisible(false)}
      afterClose={props.removeModal}
      destroyOnClose={true}
      centered
      closable={true}
      maskClosable={!spreadsheet && !workbook ? true : false}
      width={spreadsheet ? 640 : 500}
    >
      {!spreadsheet && !workbook && (
        <Upload setSpreadsheet={setSpreadsheet} setWorkbook={setWorkbook} />
      )}
      {!spreadsheet && workbook && (
        <Workbook
          workbook={workbook}
          setSpreadsheet={setSpreadsheet}
          removeModal={props.removeModal}
        />
      )}
      {spreadsheet && (
        <Mapping
          data={spreadsheet}
          dbMapping={dbMapping}
          removeModal={props.removeModal}
          columns={props.columns}
          apiFunction={props.apiFunction}
          mappingType={props.mappingType}
        />
      )}
    </Modal>
  );
}

export default Import;
