import React from "react";
import ReactDOM from "react-dom/client";
import "./_styleguide/index.scss";
import "../node_modules/@centrate-io/barn/dist/index.css";
import "../node_modules/@centrate-io/barn/dist/_fonts/fonts.scss";
import "./_styleguide/styleguide.scss";
import AuthProvider from "./_contexts/AuthContext";
import DealsProvider from "./_contexts/DealsContext";
import App from "./App";

const root = ReactDOM.createRoot(document.getElementById("root"));
root.render(
  <React.Fragment>
    <AuthProvider>
      <DealsProvider>
        <App />
      </DealsProvider>
    </AuthProvider>
  </React.Fragment>,
);
