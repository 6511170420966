import React, { useState } from "react";
import { Tabs } from "../../_styleguide";
import { IconReport } from "@tabler/icons-react";
import { useNavigate, useParams } from "react-router-dom";
import DealsTable from "./_components/DealsTable/DealsTable";
import { Header, Text } from "@centrate-io/barn";
import "./Deals.scss";

function Deals(props) {
  const navigate = useNavigate();
  const params = useParams();
  const [tab, setTab] = useState(params?.tab || "active");
  const [dealCount, setDealCount] = useState();

  let items = [
    {
      key: "active",
      label: (
        <div className="tab-name success">
          <IconReport />
          Active
        </div>
      ),
      children: <DealsTable category="active" setDealCount={setDealCount} />,
    },
    {
      key: "closed",
      label: (
        <div className="tab-name danger">
          <IconReport />
          Closed
        </div>
      ),
      children: <DealsTable category="closed" setDealCount={setDealCount} />,
    },
  ];

  return (
    <div className="admin-tabs app-deals">
      <div className="admin-header">
        <div className="crumbs">
          <Header type="display" size="5">
            <IconReport />
            Deals
            <span className="current-count">
              {dealCount && dealCount > 0 ? ` (${dealCount})` : null}
            </span>
          </Header>
          <Text type="helper">View all active or closed deals</Text>
        </div>
      </div>
      <Tabs
        onChange={(key) => {
          navigate(`/deals/${key}`);
          setTab(key);
        }}
        activeKey={tab}
        items={items}
        className={tab}
        destroyInactiveTabPane={true}
      />
    </div>
  );
}

export default Deals;
