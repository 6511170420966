import React, { useState } from "react";
import { Header, Text } from "@centrate-io/barn";
import { useParams } from "react-router-dom";

function BulkZillow(props) {
    const params = useParams();
    const deal_id = params?.id;
    return (
        <div className="crumbs" style={{padding: 32}}>
        <Header type="display" size="5">
            Bulk Zillows
        </Header>
        <Text type="helper">Upload Bulk Zillows</Text>
        </div> 
    );
  }
  
  export default BulkZillow;