import { IconUpload } from "@tabler/icons-react";
import { Upload as AntDUpload } from "antd";
import { handleFileUpload } from "../../_helpers/upload";

const { Dragger } = AntDUpload;

function Upload(props) {
  return (
    <>
      <div className="pp-modal-header">
        <div className="pp-mh-icon">
          <IconUpload />
        </div>
        Upload Data
      </div>

      <div className="upload-doc">
        <Dragger
          name="file"
          multiple={false}
          beforeUpload={(file) =>
            handleFileUpload(file, props.setSpreadsheet, props.setWorkbook)
          }
          accept=".csv, .xls, .xlsx"
        >
          <p className="ant-upload-text">
            Click or drag file to this area to upload
          </p>
          <p className="ant-upload-hint">
            Support for a single upload. Please upload CSV or Excel files.
          </p>
        </Dragger>
      </div>
    </>
  );
}

export default Upload;
