export const formatType = (type, value) => {
	let val;
	if (type === "Number" || type === "Percent") {
		val = parseFloat(value);
		return isNaN(val) ? "" : val;
	}
	if (type === "Integer") {
		val = parseInt(value);
		return isNaN(val) ? "" : val;
	}
	return value?.toString() || "";
};
