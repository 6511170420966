export const validateEmail = (email) => {
  return email?.match(
    /^(([^<>()[\]\\.,;:\s@"]+(\.[^<>()[\]\\.,;:\s@"]+)*)|(".+"))@((\[[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\])|(([a-zA-Z\-0-9]+\.)+[a-zA-Z]{2,}))$/,
  )
    ? true
    : false;
};
export const validateLastFour = (ssn) => {
  return ssn?.match(/^[0-9]{4}$/) ? true : false;
};
export const validateName = (name) => {
  return name?.match(/^(?=.{1,40}$)[a-zA-Z]+(?:[-' ][a-zA-Z]+)*$/)
    ? true
    : false;
};
export const validateZipCode = (zip) => {
  return zip?.match(/(^\d{5}$)|(^\d{5}-\d{4}$)/) ? true : false;
};
export const validateState = (state) => {
  return state?.match(
    /(A[KLRZ]|C[AOT]|D[CE]|FL|GA|HI|I[ADLN]|K[SY]|LA|M[ADEINOST]|N[CDEHJMVY]|O[HKR]|PA|RI|S[CD]|T[NX]|UT|V[AT]|W[AIVY])/,
  )
    ? true
    : false;
};
export const validateAddress = (address) => {
  return address?.match(/^[a-zA-Z0-9\s.#,'-]*$/) && address?.length > 0
    ? true
    : false;
};
export const validateString = (name) => {
  return name && name?.length > 0 ? true : false;
};

export const validateSocial = (ssn) => {
  return ssn?.match(/^\d{3}-?\d{2}-?\d{4}$/) ? true : false;
};

export const formatBytes = (a) => {
  if (0 === a) return "0 Bytes";
  var c = 1024,
    d = 0,
    e = ["Bytes", "KB", "MB", "GB", "TB", "PB", "EB", "ZB", "YB"],
    f = Math.floor(Math.log(a) / Math.log(c));
  return parseFloat((a / Math.pow(c, f)).toFixed(d)) + " " + e[f];
};

export const FormatPhone = (nput) => {
  let newValue = "";
  if (nput[0] === "+" && nput[1] === "1") nput = nput.replace("+1", "");
  const input = nput.replace(/\D/g, "").substring(0, 10);
  const zip = input.substring(0, 3);
  const middle = input.substring(3, 6);
  const last = input.substring(6, 10);

  if (input.length > 6) {
    newValue = `${zip}-${middle}-${last}`;
  } else if (input.length === 6) {
    newValue = `${zip}-${middle}`;
  } else if (input.length > 3) {
    newValue = `${zip}-${middle}`;
  } else if (input.length === 3) {
    newValue = `${zip}`;
  } else if (input.length > 0) {
    newValue = `${zip}`;
  }
  return newValue;
};

export const FormatSSN = (nput) => {
  let actualInput = nput || "";
  if (actualInput?.length <= 0) return <i>n/a</i>;
  let newValue = "";
  if (actualInput[0] === "+" && actualInput[1] === "1")
    actualInput = actualInput.replace("+1", "");
  const input = actualInput.replace(/\D/g, "").substring(0, 10);
  const zip = input.substring(0, 3);
  const middle = input.substring(3, 5);
  const last = input.substring(5, 9);

  if (input.length > 5) {
    newValue = `${zip}-${middle}-${last}`;
  } else if (input.length === 5) {
    newValue = `${zip}-${middle}`;
  } else if (input.length > 3) {
    newValue = `${zip}-${middle}`;
  } else if (input.length === 3) {
    newValue = `${zip}`;
  } else if (input.length > 0) {
    newValue = `${zip}`;
  }
  return newValue;
};

export const formatMoney = (amount) =>
  new Intl.NumberFormat("en-US", { style: "currency", currency: "USD" }).format(
    amount || 0,
  );

export const flatMoney = (amount) => {
  const val = new Intl.NumberFormat("en-US", {
    style: "currency",
    currency: "USD",
  }).format(amount || 0);
  return val.split(".")[0];
};

export const stripHTML = (html) => {
  const div = document.createElement("div");
  div.innerHTML = html;
  const text = div.textContent || div.innerText || "";
  return text;
};

export const delay = (ms) => {
  return new Promise((resolve) => setTimeout(resolve, ms));
};

export const capitalize = (string) => {
  return string.charAt(0).toUpperCase() + string.slice(1);
};

export const flatten = (data) => {
  const newObject = {};
  Object.keys(data).forEach((key) => {
    const tempObject = data[key];
    Object.keys(tempObject).forEach((field) => {
      newObject[field] = tempObject[field];
    });
  });
  return newObject;
};

export const parseAddress = (components) => {
  const now = {};
  components.forEach((c) => {
    now[c.types[0]] = {
      long: c.long_name,
      short: c.short_name,
    };
  });
  return now;
};

export const timeout = (ms) => {
  return new Promise((resolve) => setTimeout(resolve, ms));
};

export const LevenshteinDistance = function (a, b) {
  if (a.length === 0) return b.length;
  if (b.length === 0) return a.length;

  var matrix = [];

  // increment along the first column of each row
  var i;
  for (i = 0; i <= b.length; i++) {
    matrix[i] = [i];
  }

  // increment each column in the first row
  var j;
  for (j = 0; j <= a.length; j++) {
    matrix[0][j] = j;
  }

  // Fill in the rest of the matrix
  for (i = 1; i <= b.length; i++) {
    for (j = 1; j <= a.length; j++) {
      if (b.charAt(i - 1) === a.charAt(j - 1)) {
        matrix[i][j] = matrix[i - 1][j - 1];
      } else {
        matrix[i][j] = Math.min(
          matrix[i - 1][j - 1] + 1, // substitution
          Math.min(
            matrix[i][j - 1] + 1, // insertion
            matrix[i - 1][j] + 1,
          ),
        ); // deletion
      }
    }
  }

  return matrix[b.length][a.length];
};
