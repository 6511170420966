import React, { Component } from "react";
import { Select as AntdSelect } from "antd";
import "./Select.scss";

class Select extends Component {
  render() {
    const props = { ...this.props };
    props.className = "pp-select " + (props.className || "");

    if (props.started && props.valid !== 1) {
      props.className += " pp-invalid";
    }
    if (props.size === "large") {
      props.className += " pp-large";
    }
    if (props.size === "average") {
      props.className += " pp-average";
    }
    if (props.size === "medium") {
      props.className += " pp-medium";
    }
    if (props.size === "extra-medium") {
      props.className += " pp-extra-medium";
    }

    return <AntdSelect {...props} />;
  }
}

Select.Option = AntdSelect.Option;
Select.OptGroup = AntdSelect.OptGroup;
export default Select;
