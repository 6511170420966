export const loanColumns = [
  "loan_number",
  "as_of_date",
  "active_mi_flag",
  "mi_coverage",
  "curr_rate",
  "curr_rate_type",
  "int_method",
  "curr_upb",
  "deffered_prin",
  "deffered_int",
  "last_pmt_rec_date",
  "int_paid_thru_date",
  "next_due_date",
  "curr_mat_date",
  "loan_status",
  "curr_pi_pmt",
  "curr_ti_pmt",
  "accr_late_fees",
  "escrow_bal",
  "corp_advs",
  "legal_bal",
  "secured_status",
  "heloc_status",
  "curr_lien_position",
  "last_borr_contact_date",
  "last_borr_contact_outcome",
  "active_io_flag",
  "io_rem_term",
  "balloon_flag",
  "senior_orig_bal",
  "senior_curr_bal",
  "senior_curr_date",
  "senior_lien_research_date",
  "senior_lien_holder",
  "borr_name",
  "borr_fico",
  "borr_fico_date",
  "borr_orig_fico",
  "borr_ssn",
  "borr_dob",
  "co_borr_name",
  "co_borr_fico",
  "co_borr_fico_date",
  "borr_orig_fico",
  "co_borr_ssn",
  "co_borr_dob",
  "prop_street",
  "prop_city",
  "prop_county",
  "prop_state",
  "prop_zip",
  "prop_type",
  "num_of_units",
  "orig_occupancy_type",
  "curr_occupancy_type",
  "orig_ltv",
  "orig_cltv",
  "orig_appraisal",
  "orig_sale_amt",
  "curr_prop_value",
  "curr_prop_value_date",
  "curr_prop_value_type",
  "note_date",
  "orig_first_pmt_date",
  "orig_amort_term",
  "orig_term",
  "orig_balloon_flag",
  "orig_io_term",
  "orig_pi_pmt",
  "orig_ti_pmt",
  "orig_loan_amt",
  "orig_mat_date",
  "arm_index",
  "arm_margin",
  "arm_fixed_per",
  "arm_init_cap",
  "arm_per_cap",
  "arm_floor",
  "arm_ceiling",
  "orig_product_type",
  "orig_doc_type",
  "orig_loan_purpose",
  "orig_lien_position",
  "mod_type",
  "mod_date",
  "mod_first_pmt_date",
  "mod_term",
  "mod_mat_date",
  "mod_balloon_flag",
  "mod_io_term",
  "mod_rate",
  "mod_pi_pmt",
  "mod_ti_pmt",
  "step_rate_1",
  "step_date_1",
  "step_pmt_1",
  "step_rate_2",
  "step_date_2",
  "step_pmt_2",
  "step_rate_3",
  "step_date_3",
  "step_pmt_3",
  "step_rate_4",
  "step_date_4",
  "step_pmt_4",
  "step_rate_5",
  "step_date_5",
  "step_pmt_5",
  "step_rate_6",
  "step_date_6",
  "step_pmt_6",
  "step_rate_7",
  "step_date_7",
  "step_pmt_7",
  "step_rate_8",
  "step_date_8",
  "step_pmt_8",
  "step_rate_9",
  "step_date_9",
  "step_pmt_9",
  "step_rate_10",
  "step_date_10",
  "step_pmt_10",
  "step_rate_11",
  "step_date_11",
  "step_pmt_11",
  "step_rate_12",
  "step_date_12",
  "step_pmt_12",
  "step_rate_13",
  "step_date_13",
  "step_pmt_13",
  "lm_start_date",
  "lm_end_date",
  "nnd_rollforward_flag",
  "mba_paystring",
  "cf_prev_1",
  "cf_prev_2",
  "cf_prev_3",
  "cf_prev_4",
  "cf_prev_5",
  "cf_prev_6",
  "cf_prev_7",
  "cf_prev_8",
  "cf_prev_9",
  "cf_prev_10",
  "cf_prev_11",
  "cf_prev_12",
  "cf_prev_13",
  "cf_prev_14",
  "cf_prev_15",
  "cf_prev_16",
  "cf_prev_17",
  "cf_prev_18",
  "cf_prev_19",
  "cf_prev_20",
  "cf_prev_21",
  "cf_prev_22",
  "cf_prev_23",
  "cf_prev_24",
  "cf_prev_25",
  "ndd_prev_1",
  "nnd_prev_2",
  "nnd_prev_3",
  "nnd_prev_4",
  "nnd_prev_5",
  "nnd_prev_6",
  "nnd_prev_7",
  "nnd_prev_8",
  "nnd_prev_9",
  "nnd_prev_10",
  "nnd_prev_11",
  "nnd_prev_12",
  "nnd_prev_13",
  "nnd_prev_14",
  "nnd_prev_15",
  "nnd_prev_16",
  "nnd_prev_17",
  "nnd_prev_18",
  "nnd_prev_19",
  "nnd_prev_20",
  "nnd_prev_21",
  "nnd_prev_22",
  "nnd_prev_23",
  "nnd_prev_24",
  "nnd_prev_25",
  "active_bk_flag",
  "bk_chapter",
  "bk_filing_date",
  "bk_resolution_date",
  "bk_resolution_type",
  "bk_cramdown_flag",
  "bk_cramdown_status",
  "bk_count",
  "discharged_ch7_flag",
  "post_petition_due_date",
  "active_fc_flag",
  "fc_first_legal_date",
  "fc_reffered_to_att_date",
  "curr_fc_step",
  "curr_fc_step_date",
  "next_fc_step",
  "next_fc_step_date",
  "fc_sched_sale_date",
  "reo_start_date",
  "reo_status",
  "vacant_flag",
  "list_price",
  "num_of_price_reductions",
  "compliance_issues",
  "collateral_issues",
  "property_issues",
  "litigation_issues",
  "servicing_issues",
];
