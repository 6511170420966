import { useContext } from "react";
import { Dropdown, Flex } from "antd";
import { IconSelector } from "@tabler/icons-react";
import { AuthContext } from "../../../_contexts/AuthContext";
import { Link } from "react-router-dom";
import "./Deal.scss";

function Deal({ collapsed, deal }) {
  const { auth, logout } = useContext(AuthContext);
  const items = [
    {
      label: (
        <div className="drop-user">
          <h5>{deal?.name}</h5>
          <div style={{ marginTop: "16px" }}>
            <b>Status:</b>
            {deal?.status}
          </div>
          <div>
            <b>Insurer:</b>
            {deal?.insurer?.name}
          </div>
          <div>
            <b>Servicer:</b>
            {deal?.servicer?.name}
          </div>
          <div>
            <b>Created:</b>
            {deal?.date_created}
          </div>
          {deal.date_closed && (
            <div>
              <b>Closed:</b>
              {deal?.date_closed}
            </div>
          )}
        </div>
      ),
      className: "drop-user-container",
    },
    { type: "divider" },
    {
      label: (
        <Link to={`/edit-deal/${deal.id}`}>
          <span>Edit Deal</span>
        </Link>
      ),
    },
  ];

  return (
    <Flex className="deal-container" vertical={true}>
      <Dropdown
        className={`deal-drop ${collapsed ? "drop-collapsed" : ""}`}
        menu={{
          items,
        }}
        trigger={["click"]}
        overlayClassName="deal-drop-drop"
      >
        <Flex>
          <Flex align="center" justify="center">
            {deal?.name?.[0]?.toUpperCase()}
          </Flex>
          {!collapsed && <h3>{deal?.name}</h3>}
          <IconSelector size="18" />
        </Flex>
      </Dropdown>
    </Flex>
  );
}

export default Deal;
