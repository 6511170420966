// src/context/AuthContext.js
import React, { createContext, useState, useEffect } from "react";
import axios from "axios";
import jwt_decode from "jwt-decode";

const apiUrl = process.env.REACT_APP_API_URL;

export const AuthContext = createContext();

const AuthProvider = ({ children }) => {
  const [auth, setAuth] = useState({
    user: localStorage.getItem("jwt")
      ? jwt_decode(localStorage.getItem("jwt"))
      : null,
    jwt: localStorage.getItem("jwt") || null,
  });

  useEffect(() => {
    if (auth.jwt) {
      axios.defaults.headers.common["Authorization"] = `Bearer ${auth.jwt}`;
    } else {
      delete axios.defaults.headers.common["Authorization"];
    }
  }, [auth.jwt]);

  const login = async (email, password) => {
    try {
      const response = await axios.post(`${apiUrl}/users/login`, {
        email,
        password,
      });
      const { token } = response.data;
      const user = jwt_decode(token);
      if (!user) throw new Error();
      localStorage.setItem("jwt", token);
      setAuth({ user, jwt: token });
      return { success: true, data: token };
    } catch (err) {
      return {
        success: false,
        message: err.response?.data?.message || "Login failed",
      };
    }
  };

  const register = async (full_name, email, password) => {
    try {
      const response = await axios.post(`${apiUrl}/users/register`, {
        full_name,
        email,
        password,
      });
      const { user } = response.data;
      if (user) {
        return { success: true, data: user };
      } else {
        throw new Error();
      }
    } catch (err) {
      return {
        success: false,
        message: err.response?.data?.message || "Registration failed",
      };
    }
  };

  const logout = async () => {
    try {
      try {
        await axios.post(`${apiUrl}/users/logout`);
      } catch (err) {
        console.log(err);
      }
      localStorage.removeItem("jwt");
      setAuth({ user: null, jwt: null });
      window.location.href = "/";
    } catch (err) {
      console.log(err);
    }
  };

  return (
    <AuthContext.Provider
      value={{
        auth,
        login,
        register,
        logout,
      }}
    >
      {children}
    </AuthContext.Provider>
  );
};

export default AuthProvider;
