import React, { Component } from "react";
import "./InputLabel.scss";

class InputLabel extends Component {
  render() {
    const props = { ...this.props };
    props.className = "barn-label pp-input-label " + (props.className || "");

    if (props.started && !props.valid) {
      props.className += "pp-invalid";
    }

    return <label {...props} />;
  }
}

export default InputLabel;
