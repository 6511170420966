export const structure = {
  "Loan Info": [
    {
      label: "Loan Number",
      field: "loan_number",
      disabled: true,
    },
    {
      label: "Current Rate",
      field: "curr_rate",
    },
    {
      label: "Current UPB",
      field: "curr_upb",
    },
    {
      label: "Deferred Principal",
      field: "deffered_prin",
    },
    {
      label: "Deferred Interest",
      field: "deffered_int",
    },
    {
      label: "Next Due Date",
      field: "next_due_date",
    },
    {
      label: "Current Maturity Date",
      field: "curr_mat_date",
    },
  ],
  Other: [
    {
      label: "Loan Status",
      field: "loan_status",
    },
    {
      label: "Secured Status",
      field: "secured_status",
    },
    {
      label: "Current PI Amount",
      field: "curr_pi_pmt",
    },
    {
      label: "Escrow Balance",
      field: "escrow_bal",
    },
    {
      label: "Legal Balance",
      field: "legal_bal",
    },
    {
      label: "Current Lien Position",
      field: "curr_lien_position",
    },
    {
      label: "Senior Original Balance",
      field: "senior_orig_bal",
    },
  ],
};
