import { Text } from "@centrate-io/barn";
import { Link } from "react-router-dom";
import {
  IconSignature,
  IconWriting,
  IconCalendar,
  IconFile,
  IconProgressAlert,
  IconAddressBook,
  IconFileImport,
} from "@tabler/icons-react";
import { Tag } from "../../../../_styleguide";

export const getColumns = (category) => {
  return [
    {
      title: (
        <div className="column-name">
          <IconSignature />
          Name
        </div>
      ),
      dataIndex: "name",
      key: "name",
      width: 280,
      render: (name, data) => (
        <Link
          to={`/deal/${data.id}/summary-dashboard`}
          className="application-title"
        >
          <Text className="doc-name">
            <IconFile />
            <b>{data.name}</b>
          </Text>
        </Link>
      ),
    },
    {
      title: (
        <div className="column-name">
          <IconProgressAlert />
          Status
        </div>
      ),
      dataIndex: "status",
      key: "status",
      width: 130,
      align: "center",
      render: (status) => <Tag size="small">{status}</Tag>,
    },
    {
      title: (
        <div className="column-name">
          <IconAddressBook />
          Servicer
        </div>
      ),
      dataIndex: "servicer",
      key: "servicer",
      align: "left",
      render: (servicer) => <Text>{servicer?.name}</Text>,
    },
    {
      title: (
        <div className="column-name">
          <IconAddressBook />
          Insurer
        </div>
      ),
      dataIndex: "insurer",
      key: "insurer",
      align: "left",
      render: (insurer) => <Text>{insurer?.name}</Text>,
    },
    {
      title: (
        <div className="column-name">
          <IconFileImport />
          Imported
        </div>
      ),
      dataIndex: "tape_uploaded",
      key: "tape_uploaded",
      align: "left",
      width: 180,
      render: (tape_uploaded, data) => <Text>{data.loanCount} loans</Text>,
    },
    {
      title: (
        <div className="column-name">
          <IconCalendar />
          Created
        </div>
      ),
      dataIndex: "date_created",
      key: "date_created",
      align: "left",
      width: 180,
      render: (date_created) => <Text>{date_created}</Text>,
    },
    {
      title: (
        <div className="column-name">
          <IconWriting />
          Closed
        </div>
      ),
      dataIndex: "date_closed",
      key: "date_closed",
      align: "left",
      width: 180,
      render: (date_closed) => <Text>{date_closed}</Text>,
    },
  ];
};
