import { useContext } from "react";
import { Dropdown, Flex } from "antd";
import { IconSelector } from "@tabler/icons-react";
import { AuthContext } from "../../../_contexts/AuthContext";
import "./Company.scss";

function Company({ collapsed }) {
  const { auth, logout } = useContext(AuthContext);
  const user = auth?.user;
  const items = [
    {
      label: (
        <div className="drop-user">
          <h5>{user?.full_name}</h5>
          <p>{user?.email}</p>
        </div>
      ),
      className: "drop-user-container",
    },
    { type: "divider" },
    {
      label: <span>Sign out</span>,
      onClick: logout,
    },
  ];

  return (
    <Flex className="company-container" vertical={true}>
      <Dropdown
        className={`company-drop ${collapsed ? "drop-collapsed" : ""}`}
        menu={{
          items,
        }}
        trigger={["click"]}
        overlayClassName="company-drop-drop"
      >
        <Flex className="company-icon" align="center" justify="center">
          {"C"}
        </Flex>
      </Dropdown>
    </Flex>
  );
}

export default Company;
