import { message } from "@centrate-io/barn";
import * as XLSX from "xlsx";
import Papa from "papaparse";
import { LevenshteinDistance } from "../../../../_assets/js/helpers";

export const handleFileUpload = (file, setSpreadsheet, setWorkbook) => {
  const fileType = file.name.split(".").pop().toLowerCase();

  const reader = new FileReader();

  reader.onload = (e) => {
    const fileData = e.target.result;

    if (fileType === "csv") {
      Papa.parse(fileData, {
        header: true,
        complete: (results) => {
          if (Array.isArray(results?.data) && results?.data?.length > 0) {
            setSpreadsheet(results.data);
          } else {
            message.error("Error parsing CSV file");
          }
        },
        error: () => {
          message.error("Error parsing CSV file");
        },
      });
    } else if (fileType === "xlsx" || fileType === "xls") {
      const workbook = XLSX.read(fileData, { type: "binary" });
      setWorkbook({ Filename: file.name, ...workbook });
    } else {
      message.error("Unsupported file format");
    }
  };

  if (fileType === "csv") {
    reader.readAsText(file); // Correctly read the file as text
  } else if (fileType === "xlsx" || fileType === "xls") {
    reader.readAsBinaryString(file); // Correctly read the file as binary string
  }

  return false;
};

export const convertXLS = (workbook, sheetName, range, setSpreadsheet) => {
  const worksheet = workbook.Sheets[sheetName];
  const jsonData = XLSX.utils.sheet_to_json(worksheet, {
    range: range - 1,
  });
  if (Array.isArray(jsonData) && jsonData?.length > 0) {
    setSpreadsheet(jsonData);
  } else {
    message.error("Error parsing Excel file");
  }
};

export const createDefaultMapping = (data, cols, dbMapping) => {
  const mappings = [];
  const csvColumns = Object.keys(data[0]);
  csvColumns.forEach((csvColumnKey) => {
    let mapping = null;
    cols.forEach((colKey) => {
      if (dbMapping?.[csvColumnKey] === colKey) {
        mapping = {
          csvColumn: csvColumnKey,
          col: colKey,
          example: data?.[0]?.[csvColumnKey],
          isMapped: true,
        };
      } else if (LevenshteinDistance(csvColumnKey, colKey) <= 2) {
        mapping = {
          csvColumn: csvColumnKey,
          col: colKey,
          example: data?.[0]?.[csvColumnKey],
          isMapped: true,
        };
      } else if (!mapping) {
        mapping = {
          csvColumn: csvColumnKey,
          col: null,
          example: data?.[0]?.[csvColumnKey],
          isMapped: false,
        };
      }
    });
    mappings.push(mapping);
  });
  return mappings;
};

export const mapping_to_table = (mapping, data, apiFunction) => {
  // Step 1: Getting map together
  const columnMap = {};
  mapping.forEach((m) => {
    if (m.col) columnMap[m.csvColumn] = m.col;
  });

  // Step 2: Create new data
  const importTable = data.map((d) => {
    let newData = {};
    Object.keys(d).forEach((key) => {
      if (columnMap[key]) {
        newData[columnMap[key]] = d[key];
      }
    });
    return newData;
  });
  return importTable;
};
