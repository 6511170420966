import React, { useEffect, useState } from "react";
import { Header, Text } from "@centrate-io/barn";
import { useParams } from "react-router-dom";
import CommentsTable from "./_components/CommentsTable/CommentsTable";

function ServicerComments(props) {
    const params = useParams();
    const loan = params?.loan_id;
    const deal_id = params?.id;

    console.log("deal_id", deal_id);
    console.log("loan", loan);

    return (
        <>
          <CommentsTable deal_id={deal_id} loan_id={loan}/>
        </>
    );
  }
  
  export default ServicerComments;