import React, { Component } from "react";
import { Input } from "antd";
import "./TextArea.scss";

class TextArea extends Component {
  render() {
    const props = { ...this.props };
    props.className = "pp-text-area " + (props.className || "");

    if (props.started && props.valid !== 1) {
      props.className += " pp-invalid";
    }

    return (
      <div className={`pp-text-area-wrapper`}>
        <Input.TextArea {...props} />
      </div>
    );
  }
}

export default TextArea;
