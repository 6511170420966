import React, { useState } from "react";
import { Header, Text } from "@centrate-io/barn";
import { useParams } from "react-router-dom";

function TitleOrders(props) {
    const params = useParams();
    const deal_id = params?.id;
    return (
        <div className="crumbs" style={{padding: 32}}>
            <Header type="display" size="5">
                Title Orders
            </Header>
            <Text type="helper">Upload Title Orders</Text>
        </div>
    );
  }
  
  export default TitleOrders;