import { Text } from "@centrate-io/barn";
import { Link } from "react-router-dom";

export const getColumns = (category) => {
  return [
    {
      title: (
        <div className="column-name">
          Comment
        </div>
      ),
      dataIndex: "comment",
      key: "comment",
      width: 280,
      render: (name, data) => (
        
        <Text className="doc-name">
          <b># {data?.comment}</b>
        </Text>

      ),
    },
    {
      title: (
        <div className="column-name">
          Comment Date
        </div>
      ),
      dataIndex: "comment_date",
      key: "comment_date",
      width: 280,
      render: (name, data) => (
        
        <Text className="doc-name">
          <b>{data?.comment_date}</b>
        </Text>
       
      ),
    },
    {
      title: (
        <div className="column-name">
          Comment Code
        </div>
      ),
      dataIndex: "comment_code",
      key: "comment_code",
      width: 280,
      render: (name, data) => (
        
        <Text className="doc-name">
          <b>{data?.comment_code}</b>
        </Text>
       
      ),
    },
    {
      title: (
        <div className="column-name">
          Comment Type
        </div>
      ),
      dataIndex: "comment_type",
      key: "comment_type",
      width: 280,
      render: (name, data) => (
        
        <Text className="doc-name">
          <b>{data?.comment_type}</b>
        </Text>
       
      ),
    },
    {
      title: (
        <div className="column-name">
          Dept
        </div>
      ),
      dataIndex: "dept",
      key: "dept",
      width: 280,
      render: (name, data) => (
        
        <Text className="doc-name">
          <b>{data?.dept}</b>
        </Text>
       
      ),
    },
  ];
};
