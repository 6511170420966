import { Route, Routes } from "react-router-dom";
import CreateDeal from "../../CreateDeal/CreateDeal";
import EditDeal from "../../EditDeal/EditDeal";
import Deal from "../../Deal/Deal";
import Home from "../../Home/Home";
import Deals from "../../Deals/Deals";
import DataUploadHub from "../../DataUploadHub/DataUploadHub";
import SummaryDashboard from "../../SummaryDashboard/SummaryDashboard";
import SecuredWorkflow from "../../SecuredWorkflow/SecuredWorkflow";
import ExceptionReportingWorkflow from "../../ExceptionReportingWorkflow/ExceptionReportingWorkflow";
import CommentReview from "../../CommentReview/CommentReview";
import PricingModule from "../../PricingModule/PricingModule";
import AddToSale from "../../AddToSale/AddToSale";
import InfoRequests from "../../InfoRequests/InfoRequests";
import Loan from "../../Loan/Loan";

function AppRoutes(props) {
  return (
    <Routes>
      <Route path="/" element={<Deals {...props} />} />
      <Route path="/create-deal" element={<CreateDeal {...props} />} />
      <Route path="/deals/:tab" element={<Deals {...props} />} />
      <Route path="/deal/:id" element={<Deal {...props} />} />
      <Route path="/edit-deal/:id" element={<EditDeal {...props} />} />
      <Route
        path="/deal/:id/data-upload-hub"
        element={<DataUploadHub {...props} />}
      />
      <Route
        path="/deal/:id/data-upload-hub/:tab"
        element={<DataUploadHub {...props} />}
      />
      <Route
        path="/deal/:id/summary-dashboard"
        element={<SummaryDashboard {...props} />}
      />
      <Route
        path="/deal/:id/summary-dashboard/:tab"
        element={<SummaryDashboard {...props} />}
      />
      <Route
        path="/deal/:id/secured-workflow"
        element={<SecuredWorkflow {...props} />}
      />
      <Route
        path="/deal/:id/secured-workflow/:tab"
        element={<SecuredWorkflow {...props} />}
      />
      <Route
        path="/deal/:id/exception-reporting"
        element={<ExceptionReportingWorkflow {...props} />}
      />
      <Route
        path="/deal/:id/exception-reporting/:tab"
        element={<ExceptionReportingWorkflow {...props} />}
      />
      <Route
        path="/deal/:id/comment-review"
        element={<CommentReview {...props} />}
      />
      <Route
        path="/deal/:id/comment-review/:tab"
        element={<CommentReview {...props} />}
      />
      <Route
        path="/deal/:id/pricing-module"
        element={<PricingModule {...props} />}
      />
      <Route
        path="/deal/:id/pricing-module/:tab"
        element={<PricingModule {...props} />}
      />
      <Route path="/deal/:id/add-to-sale" element={<AddToSale {...props} />} />
      <Route
        path="/deal/:id/add-to-sale/:tab"
        element={<AddToSale {...props} />}
      />
      <Route
        path="/deal/:id/info-requests"
        element={<InfoRequests {...props} />}
      />
      <Route
        path="/deal/:id/info-requests/:tab"
        element={<InfoRequests {...props} />}
      />
      <Route path="/deal/:id/loan/:loan_id" element={<Loan {...props} />} />
      <Route
        path="/deal/:id/loan/:loan_id/:tab"
        element={<Loan {...props} />}
      />
    </Routes>
  );
}

export default AppRoutes;
