import React, { Component } from "react";
import { Tooltip } from "@centrate-io/barn";
import "./AvatarName.scss";

class AvatarName extends Component {
  render() {
    const props = { ...this.props };
    props.className = "pp-avatar-name " + (props.className || "");

    if (props.size) {
      props.className += " pp-size-" + props.size;
    }

    if (props.tooltip) {
      return (
        <Tooltip title={props.tooltip}>
          <div className={props.className}>{props.children || null}</div>
        </Tooltip>
      );
    } else {
      return <div className={props.className}>{props.children || null}</div>;
    }
  }
}

export default AvatarName;
