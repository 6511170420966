export const getSelectedKeys = (path) => {
  let currentPage = [];
  if (path.includes("summary-dashboard")) currentPage = ["summary-dashboard"];
  if (path.includes("secured-workflow")) currentPage = ["secured-workflow"];
  if (path.includes("exception-reporting"))
    currentPage = ["exception-reporting"];
  if (path.includes("comment-review")) currentPage = ["comment-review"];
  if (path.includes("pricing-module")) currentPage = ["pricing-module"];
  if (path.includes("add-to-sale")) currentPage = ["add-to-sale"];
  if (path.includes("info-requests")) currentPage = ["info-requests"];
  if (path.includes("data-upload-hub")) currentPage = ["data-upload-hub"];
  return currentPage;
};
