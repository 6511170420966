export const LoanType = {
	accr_late_fees: {
		"Column Name": "accr_late_fees",
		"Column Nickname": "Accrued Late Fees",
		Type: "Number",
	},
	active_bk_flag: {
		"Column Name": "active_bk_flag",
		"Column Nickname": "Active BK Flag",
		Type: "String",
	},
	active_fc_flag: {
		"Column Name": "active_fc_flag",
		"Column Nickname": "Active FC Flag",
		Type: "String",
	},
	active_io_flag: {
		"Column Name": "active_io_flag",
		"Column Nickname": "Active IO Flag",
		Type: "String",
	},
	active_mi_flag: {
		"Column Name": "active_mi_flag",
		"Column Nickname": "Active MI Flag",
		Type: "String",
	},
	arm_ceiling: {
		"Column Name": "arm_ceiling",
		"Column Nickname": "ARM Ceiling",
		Type: "Percent",
	},
	arm_fixed_per: {
		"Column Name": "arm_fixed_per",
		"Column Nickname": "ARM Fixed Periods",
		Type: "Integer",
	},
	arm_floor: {
		"Column Name": "arm_floor",
		"Column Nickname": "ARM Floor",
		Type: "Percent",
	},
	arm_index: {
		"Column Name": "arm_index",
		"Column Nickname": "ARM Index",
		Type: "String",
	},
	arm_init_cap: {
		"Column Name": "arm_init_cap",
		"Column Nickname": "ARM Initial Cap",
		Type: "Percent",
	},
	arm_margin: {
		"Column Name": "arm_margin",
		"Column Nickname": "ARM Margin",
		Type: "Percent",
	},
	arm_per_cap: {
		"Column Name": "arm_per_cap",
		"Column Nickname": "ARM Periodic Cap",
		Type: "Percent",
	},
	as_of_date: {
		"Column Name": "as_of_date",
		"Column Nickname": "As of Date",
		Type: "Date",
	},
	balloon_flag: {
		"Column Name": "balloon_flag",
		"Column Nickname": "Balloon Flag",
		Type: "String",
	},
	bk_chapter: {
		"Column Name": "bk_chapter",
		"Column Nickname": "BK Chapter",
		Type: "String",
	},
	bk_count: {
		"Column Name": "bk_count",
		"Column Nickname": "BK Count",
		Type: "Integer",
	},
	bk_cramdown_flag: {
		"Column Name": "bk_cramdown_flag",
		"Column Nickname": "BK Cramdown Flag",
		Type: "String",
	},
	bk_cramdown_status: {
		"Column Name": "bk_cramdown_status",
		"Column Nickname": "BK Cramdown Status",
		Type: "String",
	},
	bk_filing_date: {
		"Column Name": "bk_filing_date",
		"Column Nickname": "BK Filing Date",
		Type: "Date",
	},
	bk_resolution_date: {
		"Column Name": "bk_resolution_date",
		"Column Nickname": "BK Resolution Date",
		Type: "Date",
	},
	bk_resolution_type: {
		"Column Name": "bk_resolution_type",
		"Column Nickname": "BK Resolution Type",
		Type: "String",
	},
	borr_dob: {
		"Column Name": "borr_dob",
		"Column Nickname": "Borrower DOB",
		Type: "Date",
	},
	borr_fico: {
		"Column Name": "borr_fico",
		"Column Nickname": "Borrower FICO",
		Type: "Integer",
	},
	borr_fico_date: {
		"Column Name": "borr_fico_date",
		"Column Nickname": "Borrower FICO Date",
		Type: "Date",
	},
	borr_name: {
		"Column Name": "borr_name",
		"Column Nickname": "Borrower Name",
		Type: "String",
	},
	borr_orig_fico: {
		"Column Name": "borr_orig_fico",
		"Column Nickname": "Borrower Original FICO",
		Type: "Integer",
	},
	borr_ssn: {
		"Column Name": "borr_ssn",
		"Column Nickname": "Borrower SSN",
		Type: "String",
	},
	cf_prev_1: {
		"Column Name": "cf_prev_1",
		"Column Nickname": "CF Prev Month 1",
		Type: "Number",
	},
	cf_prev_10: {
		"Column Name": "cf_prev_10",
		"Column Nickname": "CF Prev Month 10",
		Type: "Number",
	},
	cf_prev_11: {
		"Column Name": "cf_prev_11",
		"Column Nickname": "CF Prev Month 11",
		Type: "Number",
	},
	cf_prev_12: {
		"Column Name": "cf_prev_12",
		"Column Nickname": "CF Prev Month 12",
		Type: "Number",
	},
	cf_prev_13: {
		"Column Name": "cf_prev_13",
		"Column Nickname": "CF Prev Month 13",
		Type: "Number",
	},
	cf_prev_14: {
		"Column Name": "cf_prev_14",
		"Column Nickname": "CF Prev Month 14",
		Type: "Number",
	},
	cf_prev_15: {
		"Column Name": "cf_prev_15",
		"Column Nickname": "CF Prev Month 15",
		Type: "Number",
	},
	cf_prev_16: {
		"Column Name": "cf_prev_16",
		"Column Nickname": "CF Prev Month 16",
		Type: "Number",
	},
	cf_prev_17: {
		"Column Name": "cf_prev_17",
		"Column Nickname": "CF Prev Month 17",
		Type: "Number",
	},
	cf_prev_18: {
		"Column Name": "cf_prev_18",
		"Column Nickname": "CF Prev Month 18",
		Type: "Number",
	},
	cf_prev_19: {
		"Column Name": "cf_prev_19",
		"Column Nickname": "CF Prev Month 19",
		Type: "Number",
	},
	cf_prev_2: {
		"Column Name": "cf_prev_2",
		"Column Nickname": "CF Prev Month 2",
		Type: "Number",
	},
	cf_prev_20: {
		"Column Name": "cf_prev_20",
		"Column Nickname": "CF Prev Month 20",
		Type: "Number",
	},
	cf_prev_21: {
		"Column Name": "cf_prev_21",
		"Column Nickname": "CF Prev Month 21",
		Type: "Number",
	},
	cf_prev_22: {
		"Column Name": "cf_prev_22",
		"Column Nickname": "CF Prev Month 22",
		Type: "Number",
	},
	cf_prev_23: {
		"Column Name": "cf_prev_23",
		"Column Nickname": "CF Prev Month 23",
		Type: "Number",
	},
	cf_prev_24: {
		"Column Name": "cf_prev_24",
		"Column Nickname": "CF Prev Month 24",
		Type: "Number",
	},
	cf_prev_25: {
		"Column Name": "cf_prev_25",
		"Column Nickname": "CF Prev Month 25",
		Type: "Number",
	},
	cf_prev_3: {
		"Column Name": "cf_prev_3",
		"Column Nickname": "CF Prev Month 3",
		Type: "Number",
	},
	cf_prev_4: {
		"Column Name": "cf_prev_4",
		"Column Nickname": "CF Prev Month 4",
		Type: "Number",
	},
	cf_prev_5: {
		"Column Name": "cf_prev_5",
		"Column Nickname": "CF Prev Month 5",
		Type: "Number",
	},
	cf_prev_6: {
		"Column Name": "cf_prev_6",
		"Column Nickname": "CF Prev Month 6",
		Type: "Number",
	},
	cf_prev_7: {
		"Column Name": "cf_prev_7",
		"Column Nickname": "CF Prev Month 7",
		Type: "Number",
	},
	cf_prev_8: {
		"Column Name": "cf_prev_8",
		"Column Nickname": "CF Prev Month 8",
		Type: "Number",
	},
	cf_prev_9: {
		"Column Name": "cf_prev_9",
		"Column Nickname": "CF Prev Month 9",
		Type: "Number",
	},
	co_borr_dob: {
		"Column Name": "co_borr_dob",
		"Column Nickname": "Co-Borrower DOB",
		Type: "Date",
	},
	co_borr_fico: {
		"Column Name": "co_borr_fico",
		"Column Nickname": "Co-Borrower FICO",
		Type: "Integer",
	},
	co_borr_fico_date: {
		"Column Name": "co_borr_fico_date",
		"Column Nickname": "Co-Borrower FICO Date",
		Type: "Date",
	},
	co_borr_name: {
		"Column Name": "co_borr_name",
		"Column Nickname": "Co-Borrower Name",
		Type: "String",
	},
	co_borr_ssn: {
		"Column Name": "co_borr_ssn",
		"Column Nickname": "Co-Borrower SSN",
		Type: "String",
	},
	collateral_issues: {
		"Column Name": "collateral_issues",
		"Column Nickname": "Collateral Issues",
		Type: "String",
	},
	compliance_issues: {
		"Column Name": "compliance_issues",
		"Column Nickname": "Compliance Issues",
		Type: "String",
	},
	corp_advs: {
		"Column Name": "corp_advs",
		"Column Nickname": "Corporate Advances",
		Type: "Percent",
	},
	curr_fc_step: {
		"Column Name": "curr_fc_step",
		"Column Nickname": "Current FC Step",
		Type: "String",
	},
	curr_fc_step_date: {
		"Column Name": "curr_fc_step_date",
		"Column Nickname": "Current FC Step Date",
		Type: "Date",
	},
	curr_lien_position: {
		"Column Name": "curr_lien_position",
		"Column Nickname": "Lien Position",
		Type: "Integer",
	},
	curr_mat_date: {
		"Column Name": "curr_mat_date",
		"Column Nickname": "Maturity Date",
		Type: "Date",
	},
	curr_occupancy_type: {
		"Column Name": "curr_occupancy_type",
		"Column Nickname": "Current Occupancy Type",
		Type: "String",
	},
	curr_pi_pmt: {
		"Column Name": "curr_pi_pmt",
		"Column Nickname": "P&I Payment",
		Type: "Number",
	},
	curr_prop_value: {
		"Column Name": "curr_prop_value",
		"Column Nickname": "Current Prop Value",
		Type: "Number",
	},
	curr_prop_value_date: {
		"Column Name": "curr_prop_value_date",
		"Column Nickname": "Current Prop Value Date",
		Type: "Date",
	},
	curr_prop_value_type: {
		"Column Name": "curr_prop_value_type",
		"Column Nickname": "Current Prop Value Type",
		Type: "String",
	},
	curr_rate: {
		"Column Name": "curr_rate",
		"Column Nickname": "Interest Rate",
		Type: "Percent",
	},
	curr_rate_type: {
		"Column Name": "curr_rate_type",
		"Column Nickname": "Interest Rate Type",
		Type: "Percent",
	},
	curr_ti_pmt: {
		"Column Name": "curr_ti_pmt",
		"Column Nickname": "T&I Payment",
		Type: "Number",
	},
	curr_upb: {
		"Column Name": "curr_upb",
		"Column Nickname": "Current UPB",
		Type: "Number",
	},
	deal_id: {
		"Column Name": "deal_id",
		"Column Nickname": "Deal ID",
		Type: "Integer",
	},
	deal_loan_id: {
		"Column Name": "deal_loan_id",
		"Column Nickname": "Deal-Loan ID",
		Type: "Integer",
	},
	deffered_int: {
		"Column Name": "deffered_int",
		"Column Nickname": "Deferred Interest",
		Type: "Number",
	},
	deffered_prin: {
		"Column Name": "deffered_prin",
		"Column Nickname": "Deferred Principal",
		Type: "Number",
	},
	discharged_ch7_flag: {
		"Column Name": "discharged_ch7_flag",
		"Column Nickname": "Discharged Chapter 7 Flag",
		Type: "String",
	},
	escrow_bal: {
		"Column Name": "escrow_bal",
		"Column Nickname": "Escrow Balance",
		Type: "Number",
	},
	fc_first_legal_date: {
		"Column Name": "fc_first_legal_date",
		"Column Nickname": "FC First Legal Date",
		Type: "Date",
	},
	fc_reffered_to_att_date: {
		"Column Name": "fc_reffered_to_att_date",
		"Column Nickname": "FC Referred to Attorney Date",
		Type: "Date",
	},
	fc_sched_sale_date: {
		"Column Name": "fc_sched_sale_date",
		"Column Nickname": "FC Scheduled Sale Date",
		Type: "Date",
	},
	heloc_status: {
		"Column Name": "heloc_status",
		"Column Nickname": "HELOC Status",
		Type: "String",
	},
	id: {
		"Column Name": "id",
		"Column Nickname": "ID",
		Type: "Integer",
	},
	int_method: {
		"Column Name": "int_method",
		"Column Nickname": "Interest Method",
		Type: "String",
	},
	int_paid_thru_date: {
		"Column Name": "int_paid_thru_date",
		"Column Nickname": "Interest Paid Through Date",
		Type: "Date",
	},
	io_rem_term: {
		"Column Name": "io_rem_term",
		"Column Nickname": "IO Remaining Term",
		Type: "Integer",
	},
	last_borr_contact_date: {
		"Column Name": "last_borr_contact_date",
		"Column Nickname": "Last Borrower Contact Date",
		Type: "Date",
	},
	last_borr_contact_outcome: {
		"Column Name": "last_borr_contact_outcome",
		"Column Nickname": "Last Borrower Contact Outcome",
		Type: "String",
	},
	last_pmt_rec_date: {
		"Column Name": "last_pmt_rec_date",
		"Column Nickname": "Last Payment Received Date",
		Type: "Date",
	},
	legal_bal: {
		"Column Name": "legal_bal",
		"Column Nickname": "Legal Balance",
		Type: "Number",
	},
	list_price: {
		"Column Name": "list_price",
		"Column Nickname": "List Price",
		Type: "Number",
	},
	litigation_issues: {
		"Column Name": "litigation_issues",
		"Column Nickname": "Litigation Issues",
		Type: "String",
	},
	lm_end_date: {
		"Column Name": "lm_end_date",
		"Column Nickname": "Los Mit End Date",
		Type: "Date",
	},
	lm_start_date: {
		"Column Name": "lm_start_date",
		"Column Nickname": "Loss Mit Start Date",
		Type: "Date",
	},
	loan_number: {
		"Column Name": "loan_number",
		"Column Nickname": "Loan Number",
		Type: "String",
	},
	loan_status: {
		"Column Name": "loan_status",
		"Column Nickname": "Loan Status",
		Type: "String",
	},
	mba_paystring: {
		"Column Name": "mba_paystring",
		"Column Nickname": "MBA Paystring",
		Type: "Number",
	},
	mi_coverage: {
		"Column Name": "mi_coverage",
		"Column Nickname": "MI Coverage",
		Type: "String",
	},
	mod_balloon_flag: {
		"Column Name": "mod_balloon_flag",
		"Column Nickname": "Mod Balloon Flag",
		Type: "String",
	},
	mod_date: {
		"Column Name": "mod_date",
		"Column Nickname": "Mod Date",
		Type: "Date",
	},
	mod_first_pmt_date: {
		"Column Name": "mod_first_pmt_date",
		"Column Nickname": "Mod First Payment Date",
		Type: "Date",
	},
	mod_io_term: {
		"Column Name": "mod_io_term",
		"Column Nickname": "Mod IO Term",
		Type: "Integer",
	},
	mod_mat_date: {
		"Column Name": "mod_mat_date",
		"Column Nickname": "Mod Maturity Date",
		Type: "Date",
	},
	mod_pi_pmt: {
		"Column Name": "mod_pi_pmt",
		"Column Nickname": "Mod P&I Payment",
		Type: "Number",
	},
	mod_rate: {
		"Column Name": "mod_rate",
		"Column Nickname": "Mod Rate",
		Type: "Percent",
	},
	mod_term: {
		"Column Name": "mod_term",
		"Column Nickname": "Mod Term",
		Type: "Integer",
	},
	mod_ti_pmt: {
		"Column Name": "mod_ti_pmt",
		"Column Nickname": "Mod T&I Payment",
		Type: "Number",
	},
	mod_type: {
		"Column Name": "mod_type",
		"Column Nickname": "Mod Type",
		Type: "String",
	},
	ndd_prev_1: {
		"Column Name": "ndd_prev_1",
		"Column Nickname": "NDD Prev Month 1",
		Type: "Date",
	},
	next_due_date: {
		"Column Name": "next_due_date",
		"Column Nickname": "Next Due Date",
		Type: "Date",
	},
	next_fc_step: {
		"Column Name": "next_fc_step",
		"Column Nickname": "Next FC Step",
		Type: "String",
	},
	next_fc_step_date: {
		"Column Name": "next_fc_step_date",
		"Column Nickname": "Next FC Step Date",
		Type: "Date",
	},
	nnd_prev_10: {
		"Column Name": "nnd_prev_10",
		"Column Nickname": "NDD Prev Month 10",
		Type: "Date",
	},
	nnd_prev_11: {
		"Column Name": "nnd_prev_11",
		"Column Nickname": "NDD Prev Month 11",
		Type: "Date",
	},
	nnd_prev_12: {
		"Column Name": "nnd_prev_12",
		"Column Nickname": "NDD Prev Month 12",
		Type: "Date",
	},
	nnd_prev_13: {
		"Column Name": "nnd_prev_13",
		"Column Nickname": "NDD Prev Month 13",
		Type: "Date",
	},
	nnd_prev_14: {
		"Column Name": "nnd_prev_14",
		"Column Nickname": "NDD Prev Month 14",
		Type: "Date",
	},
	nnd_prev_15: {
		"Column Name": "nnd_prev_15",
		"Column Nickname": "NDD Prev Month 15",
		Type: "Date",
	},
	nnd_prev_16: {
		"Column Name": "nnd_prev_16",
		"Column Nickname": "NDD Prev Month 16",
		Type: "Date",
	},
	nnd_prev_17: {
		"Column Name": "nnd_prev_17",
		"Column Nickname": "NDD Prev Month 17",
		Type: "Date",
	},
	nnd_prev_18: {
		"Column Name": "nnd_prev_18",
		"Column Nickname": "NDD Prev Month 18",
		Type: "Date",
	},
	nnd_prev_19: {
		"Column Name": "nnd_prev_19",
		"Column Nickname": "NDD Prev Month 19",
		Type: "Date",
	},
	nnd_prev_2: {
		"Column Name": "nnd_prev_2",
		"Column Nickname": "NDD Prev Month 2",
		Type: "Date",
	},
	nnd_prev_20: {
		"Column Name": "nnd_prev_20",
		"Column Nickname": "NDD Prev Month 20",
		Type: "Date",
	},
	nnd_prev_21: {
		"Column Name": "nnd_prev_21",
		"Column Nickname": "NDD Prev Month 21",
		Type: "Date",
	},
	nnd_prev_22: {
		"Column Name": "nnd_prev_22",
		"Column Nickname": "NDD Prev Month 22",
		Type: "Date",
	},
	nnd_prev_23: {
		"Column Name": "nnd_prev_23",
		"Column Nickname": "NDD Prev Month 23",
		Type: "Date",
	},
	nnd_prev_24: {
		"Column Name": "nnd_prev_24",
		"Column Nickname": "NDD Prev Month 24",
		Type: "Date",
	},
	nnd_prev_25: {
		"Column Name": "nnd_prev_25",
		"Column Nickname": "NDD Prev Month 25",
		Type: "Date",
	},
	nnd_prev_3: {
		"Column Name": "nnd_prev_3",
		"Column Nickname": "NDD Prev Month 3",
		Type: "Date",
	},
	nnd_prev_4: {
		"Column Name": "nnd_prev_4",
		"Column Nickname": "NDD Prev Month 4",
		Type: "Date",
	},
	nnd_prev_5: {
		"Column Name": "nnd_prev_5",
		"Column Nickname": "NDD Prev Month 5",
		Type: "Date",
	},
	nnd_prev_6: {
		"Column Name": "nnd_prev_6",
		"Column Nickname": "NDD Prev Month 6",
		Type: "Date",
	},
	nnd_prev_7: {
		"Column Name": "nnd_prev_7",
		"Column Nickname": "NDD Prev Month 7",
		Type: "Date",
	},
	nnd_prev_8: {
		"Column Name": "nnd_prev_8",
		"Column Nickname": "NDD Prev Month 8",
		Type: "Date",
	},
	nnd_prev_9: {
		"Column Name": "nnd_prev_9",
		"Column Nickname": "NDD Prev Month 9",
		Type: "Date",
	},
	nnd_rollforward_flag: {
		"Column Name": "nnd_rollforward_flag",
		"Column Nickname": "NDD Rollfoward Flag",
		Type: "String",
	},
	note_date: {
		"Column Name": "note_date",
		"Column Nickname": "Origination Date",
		Type: "Date",
	},
	num_of_price_reductions: {
		"Column Name": "num_of_price_reductions",
		"Column Nickname": "Number of Price Reductions",
		Type: "Integer",
	},
	num_of_units: {
		"Column Name": "num_of_units",
		"Column Nickname": "Number of Units",
		Type: "Number",
	},
	orig_amort_term: {
		"Column Name": "orig_amort_term",
		"Column Nickname": "Original Amortization Term",
		Type: "Integer",
	},
	orig_appraisal: {
		"Column Name": "orig_appraisal",
		"Column Nickname": "Original Appraisal",
		Type: "Number",
	},
	orig_balloon_flag: {
		"Column Name": "orig_balloon_flag",
		"Column Nickname": "Original Balloon Flag",
		Type: "String",
	},
	orig_cltv: {
		"Column Name": "orig_cltv",
		"Column Nickname": "Original CLTV",
		Type: "Percent",
	},
	orig_doc_type: {
		"Column Name": "orig_doc_type",
		"Column Nickname": "Original Document Type",
		Type: "String",
	},
	orig_first_pmt_date: {
		"Column Name": "orig_first_pmt_date",
		"Column Nickname": "Original First Payment Date",
		Type: "Date",
	},
	orig_io_term: {
		"Column Name": "orig_io_term",
		"Column Nickname": "Original IO Term",
		Type: "Integer",
	},
	orig_lien_position: {
		"Column Name": "orig_lien_position",
		"Column Nickname": "Original Lien Position",
		Type: "Integer",
	},
	orig_loan_amt: {
		"Column Name": "orig_loan_amt",
		"Column Nickname": "Original Loan Amount",
		Type: "Number",
	},
	orig_loan_purpose: {
		"Column Name": "orig_loan_purpose",
		"Column Nickname": "Original Loan Purpose",
		Type: "String",
	},
	orig_ltv: {
		"Column Name": "orig_ltv",
		"Column Nickname": "Original LTV",
		Type: "Percent",
	},
	orig_mat_date: {
		"Column Name": "orig_mat_date",
		"Column Nickname": "Original Maturity Date",
		Type: "Date",
	},
	orig_occupancy_type: {
		"Column Name": "orig_occupancy_type",
		"Column Nickname": "Original Occupancy Type",
		Type: "String",
	},
	orig_pi_pmt: {
		"Column Name": "orig_pi_pmt",
		"Column Nickname": "Original P&I Payment",
		Type: "Number",
	},
	orig_product_type: {
		"Column Name": "orig_product_type",
		"Column Nickname": "Original Product Type",
		Type: "String",
	},
	orig_sale_amt: {
		"Column Name": "orig_sale_amt",
		"Column Nickname": "Original Sale Amount",
		Type: "Number",
	},
	orig_term: {
		"Column Name": "orig_term",
		"Column Nickname": "Original Term",
		Type: "Integer",
	},
	orig_ti_pmt: {
		"Column Name": "orig_ti_pmt",
		"Column Nickname": "Original T&I Payment",
		Type: "Number",
	},
	post_petition_due_date: {
		"Column Name": "post_petition_due_date",
		"Column Nickname": "Post Petition Due Date",
		Type: "Date",
	},
	prop_city: {
		"Column Name": "prop_city",
		"Column Nickname": "Prop City",
		Type: "String",
	},
	prop_county: {
		"Column Name": "prop_county",
		"Column Nickname": "Prop County",
		Type: "String",
	},
	prop_state: {
		"Column Name": "prop_state",
		"Column Nickname": "Prop State",
		Type: "String",
	},
	prop_street: {
		"Column Name": "prop_street",
		"Column Nickname": "Prop Street",
		Type: "String",
	},
	prop_type: {
		"Column Name": "prop_type",
		"Column Nickname": "Prop Type",
		Type: "String",
	},
	prop_zip: {
		"Column Name": "prop_zip",
		"Column Nickname": "Prop ZIP",
		Type: "String",
	},
	property_issues: {
		"Column Name": "property_issues",
		"Column Nickname": "Property Issues",
		Type: "String",
	},
	reo_start_date: {
		"Column Name": "reo_start_date",
		"Column Nickname": "REO Start Date",
		Type: "Date",
	},
	reo_status: {
		"Column Name": "reo_status",
		"Column Nickname": "REO Status",
		Type: "String",
	},
	secured_status: {
		"Column Name": "secured_status",
		"Column Nickname": "Secured Status",
		Type: "String",
	},
	senior_curr_bal: {
		"Column Name": "senior_curr_bal",
		"Column Nickname": "Senior Current Balance",
		Type: "Number",
	},
	senior_curr_date: {
		"Column Name": "senior_curr_date",
		"Column Nickname": "Senior Current Date",
		Type: "Date",
	},
	senior_lien_holder: {
		"Column Name": "senior_lien_holder",
		"Column Nickname": "Senior Lien Holder",
		Type: "String",
	},
	senior_lien_research_date: {
		"Column Name": "senior_lien_research_date",
		"Column Nickname": "Senior Lien Research Date",
		Type: "Date",
	},
	senior_orig_bal: {
		"Column Name": "senior_orig_bal",
		"Column Nickname": "Senior Original Balance",
		Type: "Number",
	},
	servicing_issues: {
		"Column Name": "servicing_issues",
		"Column Nickname": "Servicing Issues",
		Type: "String",
	},
	step_date_1: {
		"Column Name": "step_date_1",
		"Column Nickname": "Step Date 1",
		Type: "Date",
	},
	step_date_10: {
		"Column Name": "step_date_10",
		"Column Nickname": "Step Date 10",
		Type: "Date",
	},
	step_date_11: {
		"Column Name": "step_date_11",
		"Column Nickname": "Step Date 11",
		Type: "Date",
	},
	step_date_12: {
		"Column Name": "step_date_12",
		"Column Nickname": "Step Date 12",
		Type: "Date",
	},
	step_date_13: {
		"Column Name": "step_date_13",
		"Column Nickname": "Step Date 13",
		Type: "Date",
	},
	step_date_2: {
		"Column Name": "step_date_2",
		"Column Nickname": "Step Date 2",
		Type: "Date",
	},
	step_date_3: {
		"Column Name": "step_date_3",
		"Column Nickname": "Step Date 3",
		Type: "Date",
	},
	step_date_4: {
		"Column Name": "step_date_4",
		"Column Nickname": "Step Date 4",
		Type: "Date",
	},
	step_date_5: {
		"Column Name": "step_date_5",
		"Column Nickname": "Step Date 5",
		Type: "Date",
	},
	step_date_6: {
		"Column Name": "step_date_6",
		"Column Nickname": "Step Date 6",
		Type: "Date",
	},
	step_date_7: {
		"Column Name": "step_date_7",
		"Column Nickname": "Step Date 7",
		Type: "Date",
	},
	step_date_8: {
		"Column Name": "step_date_8",
		"Column Nickname": "Step Date 8",
		Type: "Date",
	},
	step_date_9: {
		"Column Name": "step_date_9",
		"Column Nickname": "Step Date 9",
		Type: "Date",
	},
	step_pmt_1: {
		"Column Name": "step_pmt_1",
		"Column Nickname": "Step Payment 1",
		Type: "Number",
	},
	step_pmt_10: {
		"Column Name": "step_pmt_10",
		"Column Nickname": "Step Payment 10",
		Type: "Number",
	},
	step_pmt_11: {
		"Column Name": "step_pmt_11",
		"Column Nickname": "Step Payment 11",
		Type: "Number",
	},
	step_pmt_12: {
		"Column Name": "step_pmt_12",
		"Column Nickname": "Step Payment 12",
		Type: "Number",
	},
	step_pmt_13: {
		"Column Name": "step_pmt_13",
		"Column Nickname": "Step Payment 13",
		Type: "Number",
	},
	step_pmt_2: {
		"Column Name": "step_pmt_2",
		"Column Nickname": "Step Payment 2",
		Type: "Number",
	},
	step_pmt_3: {
		"Column Name": "step_pmt_3",
		"Column Nickname": "Step Payment 3",
		Type: "Number",
	},
	step_pmt_4: {
		"Column Name": "step_pmt_4",
		"Column Nickname": "Step Payment 4",
		Type: "Number",
	},
	step_pmt_5: {
		"Column Name": "step_pmt_5",
		"Column Nickname": "Step Payment 5",
		Type: "Number",
	},
	step_pmt_6: {
		"Column Name": "step_pmt_6",
		"Column Nickname": "Step Payment 6",
		Type: "Number",
	},
	step_pmt_7: {
		"Column Name": "step_pmt_7",
		"Column Nickname": "Step Payment 7",
		Type: "Number",
	},
	step_pmt_8: {
		"Column Name": "step_pmt_8",
		"Column Nickname": "Step Payment 8",
		Type: "Number",
	},
	step_pmt_9: {
		"Column Name": "step_pmt_9",
		"Column Nickname": "Step Payment 9",
		Type: "Number",
	},
	step_rate_1: {
		"Column Name": "step_rate_1",
		"Column Nickname": "Step Rate 1",
		Type: "Percent",
	},
	step_rate_10: {
		"Column Name": "step_rate_10",
		"Column Nickname": "Step Rate 10",
		Type: "Percent",
	},
	step_rate_11: {
		"Column Name": "step_rate_11",
		"Column Nickname": "Step Rate 11",
		Type: "Percent",
	},
	step_rate_12: {
		"Column Name": "step_rate_12",
		"Column Nickname": "Step Rate 12",
		Type: "Percent",
	},
	step_rate_13: {
		"Column Name": "step_rate_13",
		"Column Nickname": "Step Rate 13",
		Type: "Percent",
	},
	step_rate_2: {
		"Column Name": "step_rate_2",
		"Column Nickname": "Step Rate 2",
		Type: "Percent",
	},
	step_rate_3: {
		"Column Name": "step_rate_3",
		"Column Nickname": "Step Rate 3",
		Type: "Percent",
	},
	step_rate_4: {
		"Column Name": "step_rate_4",
		"Column Nickname": "Step Rate 4",
		Type: "Percent",
	},
	step_rate_5: {
		"Column Name": "step_rate_5",
		"Column Nickname": "Step Rate 5",
		Type: "Percent",
	},
	step_rate_6: {
		"Column Name": "step_rate_6",
		"Column Nickname": "Step Rate 6",
		Type: "Percent",
	},
	step_rate_7: {
		"Column Name": "step_rate_7",
		"Column Nickname": "Step Rate 7",
		Type: "Percent",
	},
	step_rate_8: {
		"Column Name": "step_rate_8",
		"Column Nickname": "Step Rate 8",
		Type: "Percent",
	},
	step_rate_9: {
		"Column Name": "step_rate_9",
		"Column Nickname": "Step Rate 9",
		Type: "Percent",
	},
	vacant_flag: {
		"Column Name": "vacant_flag",
		"Column Nickname": "Vacant Flag",
		Type: "String",
	},
};
