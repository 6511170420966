// src/components/EditDeal.js
import React, { useState, useContext, useEffect } from "react";
import {
  Form,
  Input,
  Button,
  Layout,
  Typography,
  Select,
  DatePicker,
} from "antd";
import { useParams, useNavigate } from "react-router-dom";
import { DealsContext } from "../../_contexts/DealsContext";
import axios from "../../_helpers/axios";
import moment from "moment";

const { Content } = Layout;
const { Title } = Typography;
const { Option } = Select;

const EditDeal = () => {
  const { editDeal } = useContext(DealsContext);
  const { id } = useParams();
  const [deal, setDeal] = useState(null); // Use null as initial state
  const navigate = useNavigate();
  const apiUrl = process.env.REACT_APP_API_URL;

  useEffect(() => {
    const fetchDeal = async () => {
      try {
        const response = await axios.get(`${apiUrl}/deals/${id}`);
        const dealData = response.data;
        setDeal({
          ...dealData,
          servicer: dealData.servicer.name,
          insurer: dealData.insurer.name,
          dateCreated: moment(dealData.dateCreated),
          dateClosed: dealData.dateClosed ? moment(dealData.dateClosed) : null,
        });
      } catch (error) {
        console.error("Failed to fetch deal", error);
      }
    };

    fetchDeal();
  }, [id, apiUrl]);

  const handleSubmit = async (values) => {
    try {
      const updatedDeal = {
        ...values,
        dateCreated: values.dateCreated.toISOString(),
        dateClosed: values.dateClosed ? values.dateClosed.toISOString() : null,
      };
      await editDeal(id, updatedDeal);
      navigate(`/deal/${id}`);
    } catch (error) {
      console.error("Failed to edit deal", error);
    }
  };

  if (!deal) {
    return <div>Loading...</div>;
  }

  return (
    <Layout className="layout">
      <Content>
        <div style={{ maxWidth: "550px", margin: "50px" }}>
          <div
            style={{
              display: "flex",
              justifyContent: "flex-start",
              alignItems: "flex-end",
              marginBottom: "10px",
            }}
          >
            <Title level={3} style={{ margin: 0 }}>
              Edit Deal
            </Title>
            <Button
              type="link"
              style={{ marginLeft: "20px", padding: "0px", height: "auto" }}
              onClick={() => navigate(`/deal/${id}`)}
            >
              Cancel
            </Button>
          </div>
          <Form
            onFinish={handleSubmit}
            initialValues={deal} // Set initial values for the form
            layout="vertical"
          >
            <Form.Item
              label="Deal Name"
              name="name"
              rules={[
                { required: true, message: "Please input the deal name!" },
              ]}
            >
              <Input placeholder="Deal Name" />
            </Form.Item>
            <Form.Item
              label="Insurer"
              name="insurer"
              rules={[{ required: true, message: "Please select an insurer!" }]}
            >
              <Select placeholder="Select Insurer">
                <Option value="insurer1">Insurer 1</Option>
                <Option value="insurer2">Insurer 2</Option>
                <Option value="insurer3">Insurer 3</Option>
              </Select>
            </Form.Item>
            <Form.Item
              label="Servicer"
              name="servicer"
              rules={[{ required: true, message: "Please select a servicer!" }]}
            >
              <Select placeholder="Select Servicer">
                <Option value="servicer1">Servicer 1</Option>
                <Option value="servicer2">Servicer 2</Option>
                <Option value="servicer3">Servicer 3</Option>
              </Select>
            </Form.Item>
            <Form.Item
              label="Status"
              name="status"
              rules={[{ required: true, message: "Please select a status!" }]}
            >
              <Select placeholder="Select Status">
                <Option value="active">Active</Option>
                <Option value="closed">Closed</Option>
                <Option value="pending">Pending</Option>
              </Select>
            </Form.Item>
            <Form.Item
              label="Date Created"
              name="dateCreated"
              rules={[
                { required: true, message: "Please select the date created!" },
              ]}
            >
              <DatePicker style={{ width: "100%" }} />
            </Form.Item>
            <Form.Item label="Date Closed" name="dateClosed">
              <DatePicker style={{ width: "100%" }} />
            </Form.Item>
            <Form.Item>
              <Button
                type="primary"
                htmlType="submit"
                style={{ marginTop: "30px" }}
              >
                Save Deal
              </Button>
            </Form.Item>
          </Form>
        </div>
      </Content>
    </Layout>
  );
};

export default EditDeal;
