import React, { useState, useEffect } from "react";
import { Search, Table } from "../../../../../../_styleguide";
import { message } from "@centrate-io/barn";
import { getColumns } from "./Columns";
import axios, { defaultParams } from "../../../../../../_helpers/axios";
import "./CommentsTable.scss";

function CommentsTable(props) {
  const [state, setState] = useState({
    loading: true,
    data: [],
    pagination: { pageSize: 10, showSizeChanger: false },
  });
  const [search, setSearch] = useState();
  const [page, setPage] = useState(1);
  const columns = getColumns();
  const apiUrl = process.env.REACT_APP_API_URL;
  
  
  // Load Data
  const fetchData = async (params = defaultParams) => {
    try {
      const response = await axios.get(
        `${apiUrl}/comments`,
        { 
          params: {
            ...params,
            search: search,
            deal_id: props?.deal_id,
            loan_id: props?.loan_id,
          },
        },
      );
      const pagination = { ...state.pagination };
      pagination.total = response?.data?.pageTotal;
      pagination.totalAll = response?.data?.total;
      setPage(params?.page || 1);
      //props?.setLoanCount(pagination.totalAll);
      const allCommentsData = response?.data?.data || [];
      const commentData = allCommentsData
      setState({
        loading: false,
        data: commentData,
        pagination: pagination,
      });
    } catch (error) {
      message.error("Failed to fetch comments data");
      setState({
        ...state,
        loading: false,
      });
    }
  };

  const handleTableChange = (pagination, filters) => {
    const pager = { ...state.pagination };
    pager.current = pagination.current;
    setState({ pagination: pager });
    fetchData({
      results: pagination.pageSize,
      page: pagination.current,
    });
  };

  useEffect(() => {
    fetchData();
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);
  const startResults = (page - 1) * defaultParams?.results + 1;
  const endResults = startResults + state?.data?.length - 1;

  return (
    <div style={{padding: 32}}>
      <div className="admin-search">
        <Search
          placeholder={`Search comments...`}
          value={search}
          onChange={(e) => setSearch(e.target.value)}
          onSearch={() => {
            fetchData({ ...defaultParams, search });
          }}
          style={{ width: 304 }}
        />
      </div>
      <div className="admin-table deals-table">
        <Table
          columns={columns}
          loading={state.loading}
          pagination={state.pagination}
          onChange={handleTableChange}
          rowKey={(record) => record.id}
          dataSource={state?.data}
          showSorterTooltip={false}
          scroll={{
            y: "calc(100vh - 400px)",
            x: "max-content",
          }}
          locale={{
            emptyText: state.loading
              ? `Loading Data...`
              : `No Data found`,
          }}
        />
      </div>
      {state?.data?.length > 0 && (
        <div className="admin-pagination">
          Showing {startResults}-{endResults} of {state?.pagination?.total || 0}{" "}
        </div>
      )}
    </div>
  );
}

export default CommentsTable;
