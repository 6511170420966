import React, { useState, useContext, useEffect } from "react";
import {
  Form,
  Input,
  Button,
  Layout,
  Typography,
  Select,
  DatePicker,
  Modal,
} from "antd";
import { DealsContext } from "../../_contexts/DealsContext";
import { useNavigate } from "react-router-dom";
import axios from "../../_helpers/axios";

const { Content } = Layout;
const { Title } = Typography;
const { Option } = Select;

const CreateDeal = () => {
  const { createDeal } = useContext(DealsContext);
  const [deal, setDeal] = useState({
    name: "",
    insurer_id: "",
    servicer_id: "",
    status: "active",
    dateClosed: null,
    tape_uploaded: false,
  });
  const [servicers, setServicers] = useState([]);
  const [insurers, setInsurers] = useState([]);
  const [newServicer, setNewServicer] = useState("");
  const [newInsurer, setNewInsurer] = useState("");
  const [isServicerModalVisible, setIsServicerModalVisible] = useState(false);
  const [isInsurerModalVisible, setIsInsurerModalVisible] = useState(false);
  const navigate = useNavigate();
  const apiUrl = process.env.REACT_APP_API_URL;
  const fetchData = async () => {
    try {
      const servicersResponse = await axios.get(`${apiUrl}/servicers`);
      setServicers(servicersResponse.data);
      const insurersResponse = await axios.get(`${apiUrl}/insurers`);
      setInsurers(insurersResponse.data);
    } catch (error) {
      console.error("Failed to fetch data", error);
    }
  };
  useEffect(() => {
    fetchData();
  }, []);

  const handleSubmit = async () => {
    let deals = [];
    deals = [...deals, deal];
    console.log(deals);
    try {
      await createDeal(deals);
      navigate("/");
    } catch (error) {
      console.error("Failed to create deal", error);
    }
  };

  const handleAddServicer = async () => {
    try {
      const response = await axios.post(`${apiUrl}/servicers/add`, [
        { name: newServicer },
      ]);
      setServicers([...servicers, response.data]);
      setIsServicerModalVisible(false);
      setNewServicer("");
      fetchData();
    } catch (error) {
      console.error("Failed to add servicer", error);
    }
  };

  const handleAddInsurer = async () => {
    try {
      const response = await axios.post(`${apiUrl}/insurers/add`, [
        { name: newInsurer },
      ]);
      setInsurers([...insurers, response.data]);
      setIsInsurerModalVisible(false);
      setNewInsurer("");
      fetchData();
    } catch (error) {
      console.error("Failed to add insurer", error);
    }
  };

  return (
    <Layout className="layout">
      <Content>
        <div style={{ maxWidth: "550px", margin: "50px" }}>
          <Title level={4}>Create Deal</Title>
          <Form onFinish={handleSubmit} layout="vertical">
            <Form.Item
              label="Deal Name"
              name="name"
              rules={[
                { required: true, message: "Please input the deal name!" },
              ]}
            >
              <Input
                value={deal.name}
                onChange={(e) => setDeal({ ...deal, name: e.target.value })}
                placeholder="Deal Name"
              />
            </Form.Item>
            <Form.Item
              label="Insurer"
              name="insurer"
              rules={[{ required: true, message: "Please select an insurer!" }]}
            >
              <Select
                value={deal.insurer}
                onChange={(value) => setDeal({ ...deal, insurer_id: value })}
                placeholder="Select Insurer"
                dropdownRender={(menu) => (
                  <>
                    {menu}
                    <Button
                      type="link"
                      onClick={() => setIsInsurerModalVisible(true)}
                    >
                      Add new insurer
                    </Button>
                  </>
                )}
              >
                {insurers.map((insurer) => (
                  <Option key={insurer.id} value={insurer.id}>
                    {insurer.name}
                  </Option>
                ))}
              </Select>
            </Form.Item>
            <Form.Item
              label="Servicer"
              name="servicer"
              rules={[{ required: true, message: "Please select a servicer!" }]}
            >
              <Select
                value={deal.servicer}
                onChange={(value) => setDeal({ ...deal, servicer_id: value })}
                placeholder="Select Servicer"
                dropdownRender={(menu) => (
                  <>
                    {menu}
                    <Button
                      type="link"
                      onClick={() => setIsServicerModalVisible(true)}
                    >
                      Add new servicer
                    </Button>
                  </>
                )}
              >
                {servicers.map((servicer) => (
                  <Option key={servicer.id} value={servicer.id}>
                    {servicer.name}
                  </Option>
                ))}
              </Select>
            </Form.Item>
            <Form.Item>
              <Button
                type="primary"
                htmlType="submit"
                style={{ marginTop: "30px" }}
              >
                Create Deal
              </Button>
            </Form.Item>
          </Form>
        </div>
      </Content>
      <Modal
        title="Add New Servicer"
        visible={isServicerModalVisible}
        onOk={handleAddServicer}
        onCancel={() => setIsServicerModalVisible(false)}
      >
        <Input
          value={newServicer}
          onChange={(e) => setNewServicer(e.target.value)}
          placeholder="Servicer Name"
        />
      </Modal>
      <Modal
        title="Add New Insurer"
        visible={isInsurerModalVisible}
        onOk={handleAddInsurer}
        onCancel={() => setIsInsurerModalVisible(false)}
      >
        <Input
          value={newInsurer}
          onChange={(e) => setNewInsurer(e.target.value)}
          placeholder="Insurer Name"
        />
      </Modal>
    </Layout>
  );
};

export default CreateDeal;
