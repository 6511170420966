import axios from "../_helpers/axios";
import { message } from "@centrate-io/barn";
import { getErrors } from "../_helpers/api";

export const exportDeals = async () => {
  try {
    const response = await axios({
      url: `${process.env.REACT_APP_API_URL}/downloads/deals`,
      method: "POST",
      responseType: "blob",
    });

    const url = window.URL.createObjectURL(new Blob([response.data]));
    const link = document.createElement("a");
    link.href = url;
    link.setAttribute("download", "deals_export.csv"); // Filename for the downloaded file
    document.body.appendChild(link);
    link.click();
    message.success("Deals successfully exported");
  } catch (err) {
    message.error(getErrors(err) || "Error exporting deals, try again");
  }
};
