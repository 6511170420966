import { Link } from "react-router-dom";
import {
  IconFiles,
  IconFileInfo,
  IconUpload,
  IconChartPie,
  IconCloudLock,
  IconReorder,
  IconMessage,
  IconReceipt2,
  IconCashRegister,
  IconChevronLeft,
} from "@tabler/icons-react";

export const getDealId = () => {
  const url = window.location.pathname;
  if (url.includes("/deal/")) {
    const id = url.split("/deal/")[1].split("/")[0];
    return id;
  } else {
    return null;
  }
};

const getItem = (label, key, icon, children) => ({
  key,
  icon,
  children,
  label,
});

export const dealMenuItems = (deal) => {
  if (!deal) return [];
  const items = [
    getItem(
      <Link to={`/deal/${deal.id}/summary-dashboard`}>Summary Dashboard</Link>,
      "summary-dashboard",
      <IconChartPie />,
    ),
    getItem(
      <Link to={`/deal/${deal.id}/secured-workflow`}>Secured Workflow</Link>,
      "secured-workflow",
      <IconCloudLock />,
    ),
    getItem(
      <Link to={`/deal/${deal.id}/exception-reporting`}>
        Exception Reporting
      </Link>,
      "exception-reporting",
      <IconReorder />,
    ),
    getItem(
      <Link to={`/deal/${deal.id}/comment-review`}>Comment Review</Link>,
      "comment-review",
      <IconMessage />,
    ),
    getItem(
      <Link to={`/deal/${deal.id}/pricing-module`}>Pricing Module</Link>,
      "pricing-module",
      <IconReceipt2 />,
    ),
    getItem(
      <Link to={`/deal/${deal.id}/add-to-sale`}>Add to Sale</Link>,
      "add-to-sale",
      <IconCashRegister />,
    ),
    getItem(
      <Link to={`/deal/${deal.id}/info-requests`}>Info Requests</Link>,
      "info-requests",
      <IconFileInfo />,
    ),
    getItem(
      <Link to={`/deal/${deal.id}/data-upload-hub`}>Data Upload Hub</Link>,
      "data-upload-hub",
      <IconUpload />,
    ),
    { type: "divider" },
    getItem(
      <Link to={`/`}>Back to Deals</Link>,
      "back-to-deals",
      <IconChevronLeft />,
    ),
  ];
  return items;
};
