import React, { Component } from "react";
import { Input } from "antd";
import "./Search.scss";

class Search extends Component {
  render() {
    const props = { ...this.props };
    props.className = "pp-search " + (props.className || "");
    return <Input.Search {...props} />;
  }
}

export default Search;
