import React, { useEffect, useState } from "react";
import { Header, Text } from "@centrate-io/barn";
import { useParams } from "react-router-dom";
import { message } from "@centrate-io/barn";
import { Button } from "@centrate-io/barn";
import axios from "../../../../_helpers/axios";
import Import from "../../../_components/Import/Import";
import DollarScrubTable from "./_components/DollarScrubTable/DollarScrubTable";

function DollarScrub(props) {
    const params = useParams();
    const deal_id = params?.id;
    const [loading, setLoading] = useState(true);
    const [loans, setLoans] = useState([]);
    const apiUrl = process.env.REACT_APP_API_URL;

    const [importModal, setImportModal] = useState(false);

    const addDollarScrubs = async (dollarScrubs) => {
      const updatedDollarScrubs = dollarScrubs.map((dollarScrub) => {
        const loanNumber = dollarScrub?.loan_number;
        const loan = loans.find((loan) => Number(loan.loan_number) === loanNumber);
        const loanId = loan?.id;
        const { loan_number, ...dollarScrubForUpload } = dollarScrub;
        const last_sale_date = Number(dollarScrub.last_sale_date)
        const dealID = Number(deal_id);
        return { ...dollarScrubForUpload, loan_id: loanId, deal_id: dealID, last_sale_date: last_sale_date };
      });
      setImportModal(false);
      try {
        console.log(updatedDollarScrubs);
        const response = await axios.post(`${apiUrl}/dollar-scrubs/add`, updatedDollarScrubs);
        if (response?.data[0]?.error) {
          message.error(response?.data[0]?.error);
          return;
        }
        message.success("Dollar Scrubs added successfully");
        return response;
      } catch (error) {
        message.error(error, "Failed to add dollar scrubs");
      }
    };

    const dollarScrubColumns = [
        "loan_number",
        "curr_owner",
        "last_sale_date",
        "last_sale_price",
        "borr_match",
        "prop_located",
        "high_chance_unsecured"
    ]

    useEffect(() => {
      const fetchLoans = async () => {
        try {
          const response = await axios.get(
            `${apiUrl}/loans/loans-mapping-route/${deal_id}`,
          );
          setLoans(response.data);
          setLoading(false);
        } catch (error) {
          message.error("Failed to fetch loans");
          setLoading(false);
        }
      };
      fetchLoans();
    }, [deal_id, apiUrl]);

    
    return (
      <>
      {importModal ? (
      <Import removeModal={() => setImportModal(false)} apiFunction={addDollarScrubs} columns={dollarScrubColumns}/>
      ) : null}
      {loading ? <div>Loading...</div> :
      loans?.length === 0 ? <div style={{paddingLeft: 32}}>No Deal Found</div> : 
      <div className="crumbs">
          <Header type="display" size="5" style={{padding: 32}}>
              Import Dollar Scrub
          </Header>
          <Button type="secondary" style={{margin: "0px 32px"}} onClick={() => setImportModal(true)}>
              Import
          </Button>
          <DollarScrubTable deal_id={deal_id}/>
      </div>}
      </>
    );
}
  
  export default DollarScrub;