import { useState } from "react";
import { createDefaultMapping, mapping_to_table } from "../../_helpers/upload";
import { IconLine } from "@tabler/icons-react";
import { Select } from "../../../../../_styleguide";
import { Button, Modal } from "@centrate-io/barn";
import { submitMapping } from "../../_helpers/mapping";
import "./Mapping.scss";

function Mapping(props) {
  const [mapping, setMapping] = useState(
    createDefaultMapping(props.data, props.columns, props.dbMapping),
  );
  const cols = props.columns;
  const changeRow = (index, value) => {
    let newMapping = [...mapping];
    newMapping[index].col = value;
    newMapping[index].isMapped = true;
    setMapping(newMapping);
  };

  const taken = new Set(
    mapping.filter((m) => m.col !== null).map((m) => m.col) || [],
  );

  const uploadLoans = async () => {
    Modal.confirm({
      title: "Are you sure you want import this Data?",
      okText: "Import Data",
      content:
        "Be sure to double check and make sure your columns are mapped properly before uploading",
      onOk: async () => {
        const actualData = mapping_to_table(mapping, props.data);
        // If mapping is setup, submit latest to database
        if (props.mappingType) {
          await submitMapping(mapping, props.mappingType);
        }
        await props.apiFunction(actualData);
      },
    });
  };

  return (
    <div className="import-mapping">
      <div className="pp-modal-header">
        <div className="pp-mh-icon">
          <IconLine />
        </div>
        Map Sheet Columns
      </div>
      <div className="wb-headers">
        <div>Upload Column</div>
        <div>Data Column</div>
        <div>Example Value</div>
      </div>

      <div className="wb-work">
        {mapping?.map((m, i) => (
          <div key={`wb-map-${i}`} className="wb-map">
            <div>
              <b>{m.csvColumn}</b>
            </div>
            <div>
              <Select
                showSearch
                allowClear={true}
                value={m.col}
                onChange={(val) => changeRow(i, val)}
                size="medium"
                placeholder="Ignore"
                popupClassName="pp-select-dropdown ppsd-task"
              >
                {cols?.map((u, j) => (
                  <Select.Option
                    disabled={taken.has(u)}
                    key={`lc-${j}`}
                    value={u}
                  >
                    {u}
                  </Select.Option>
                ))}
              </Select>
            </div>
            <div>
              <i>{m.example || "n/a"}</i>
            </div>
          </div>
        ))}
      </div>
      <div className="wb-actions">
        <Button type="secondary" onClick={props.removeModal}>
          Cancel
        </Button>
        <Button type="primary" onClick={uploadLoans}>
          Upload {props.data?.length} data points
        </Button>
      </div>
    </div>
  );
}

export default Mapping;
