import { useState } from "react";
import { IconFileSpreadsheet } from "@tabler/icons-react";
import {
  Flex,
  InputLabel,
  Select,
  InputNumber,
} from "../../../../../_styleguide";
import { Button } from "@centrate-io/barn";
import { convertXLS } from "../../_helpers/upload";
import "./Workbook.scss";

function Upload(props) {
  const [worksheet, setWorksheet] = useState();
  const [range, setRange] = useState(1);

  const { workbook } = props;

  const continueFlow = () => {
    try {
      convertXLS(workbook, worksheet, range, props.setSpreadsheet);
    } catch (err) {
      console.log(err);
    }
  };

  return (
    <div className="import-workbook">
      <div className="pp-modal-header">
        <div className="pp-mh-icon">
          <IconFileSpreadsheet />
        </div>
        {workbook.Filename}
      </div>

      <div className="form-workbook">
        <Flex vertical={true}>
          <InputLabel htmlFor="notify">Worksheet Name *</InputLabel>
          <Select
            value={worksheet}
            onChange={(val) => setWorksheet(val)}
            size="extra-medium"
            placeholder="Select Worksheet..."
            popupClassName="pp-select-dropdown ppsd-task"
          >
            {workbook?.SheetNames?.map((u, i) => (
              <Select.Option key={`sheetname-${i}`} value={u}>
                {u.name}
              </Select.Option>
            ))}
          </Select>
        </Flex>
        <Flex vertical={true}>
          <InputLabel htmlFor="delay">Starting Row *</InputLabel>
          <InputNumber
            precision={0}
            min={1}
            value={range}
            onChange={(val) => setRange(val)}
          />
          <p className="pp-form-info">* Which row does the header start on?</p>
        </Flex>
      </div>
      <div className="wb-actions">
        <Button type="secondary" onClick={props.removeModal}>
          Cancel
        </Button>
        <Button type="primary" disabled={!worksheet} onClick={continueFlow}>
          Continue
        </Button>
      </div>
    </div>
  );
}

export default Upload;
