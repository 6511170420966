import { Text } from "@centrate-io/barn";
import { Link } from "react-router-dom";

export const getColumns = (category) => {
  return [
    {
      title: (
        <div className="column-name">
          Loan Number
        </div>
      ),
      dataIndex: "loan_number",
      key: "loan_number",
      width: 280,
      render: (name, data) => (
        <Link to={`/deal/${data?.deal_id}/loan/${data?.loan_id}`} className="application-title">
         <Text className="doc-name">
            <b># {data?.loan?.loan_number}</b>
          </Text>
        </Link>
      ),
    },
    {
      title: (
        <div className="column-name">
          Borrower
        </div>
      ),
      dataIndex: "borrower",
      key: "borrower",
      width: 280,
      render: (name, data) => (
         <Text className="doc-name">
            <b>{data?.loan?.borr_name}</b>
          </Text>
      ),
    },
    {
      title: (
        <div className="column-name">
          Current Owner
        </div>
      ),
      dataIndex: "curr_owner",
      key: "curr_owner",
      width: 200,
      render: (name, data) => (
        
        <Text className="doc-name">
          <b>{data.curr_owner}</b>
        </Text>
       
      ),
    },
    {
      title: (
        <div className="column-name">
          Last Sale Date
        </div>
      ),
      dataIndex: "last_sale_date",
      key: "last_sale_date",
      width: 200,
      render: (name, data) => (
        
        <Text className="doc-name">
          <b>{data.last_sale_date}</b>
        </Text>
       
      ),
    },
    {
      title: (
        <div className="column-name">
          Last Sale Price
        </div>
      ),
      dataIndex: "last_sale_price",
      key: "last_sale_price",
      width: 200,
      render: (name, data) => (
        
        <Text className="doc-name">
          <b>{data.last_sale_price}</b>
        </Text>
       
      ),
    },
    {
      title: (
        <div className="column-name">
          Borrower Match
        </div>
      ),
      dataIndex: "borr_match",
      key: "borr_match",
      width: 200,
      render: (name, data) => (
        
        <Text className="doc-name">
          <b>{data.borr_match}</b>
        </Text>
       
      ),
    },
    {
      title: (
        <div className="column-name">
          Property Located
        </div>
      ),
      dataIndex: "prop_located",
      key: "prop_located",
      width: 200,
      render: (name, data) => (
        
        <Text className="doc-name">
          <b>{data.prop_located}</b>
        </Text>
       
      ),
    },
    {
      title: (
        <div className="column-name">
          High Chance Unsecured
        </div>
      ),
      dataIndex: "high_chance_unsecured",
      key: "high_chance_unsecured",
      width: 200,
      render: (name, data) => (
        
        <Text className="doc-name">
          <b>{data.high_chance_unsecured}</b>
        </Text>
  
      ),
    }
  ];
};