import React, { useState } from "react";
import { Header, Text } from "@centrate-io/barn";

function ValuesPictures(props) {
    const loan = props?.loan;
    console.log(loan);
    
    return (
        <div className="crumbs" style={{padding: 32}}>
        <Header type="display" size="5">
            Values & Property Pictures
        </Header>
        </div> 
    );
  }
  
  export default ValuesPictures;