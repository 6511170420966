import React, { useState } from "react";
import { Header, Text } from "@centrate-io/barn";
import { useParams } from "react-router-dom";

function CrsPropertyData(props) {
    const params = useParams();
    const deal_id = params?.id;
    return (
        <div className="crumbs" style={{padding: 32}}>
        <Header type="display" size="5">
            CRS Property Data
        </Header>
        <Text type="helper">Upload CRS Property Data</Text>
        </div>
    );
  }
  
  export default CrsPropertyData;