import React, { useEffect, useState } from "react";
import { Header, Text } from "@centrate-io/barn";
import { Navigate, useParams } from "react-router-dom";
import { message } from "@centrate-io/barn";
import { Button } from "@centrate-io/barn";
import axios, { defaultParams } from "../../../../_helpers/axios";
import Import from "../../../_components/Import/Import";

function ServicerComments(props) {
    const params = useParams();
    const deal_id = params?.id;
    const [loading, setLoading] = useState(true);
    const [loans, setLoans] = useState([]);
    const apiUrl = process.env.REACT_APP_API_URL;

    const [importModal, setImportModal] = useState(false);

    const addComments = async (comments) => {
      const updatedComments = comments.map((comment) => {
        const loanNumber = comment?.loan_number;
        const loan = loans.find((loan) => Number(loan.loan_number) === loanNumber);
        const loanId = loan?.id;
        const { loan_number, ...commentforUpload } = comment;
        const comment_date = Number(comment.comment_date)
        return { ...commentforUpload, loan_id: loanId, comment_date: comment_date, deal_id: Number(deal_id) };
      });
      setImportModal(false);
      try {
        const response = await axios.post(`${apiUrl}/comments/add`, updatedComments);
        if (response?.data[0]?.error) {
          message.error(response?.data[0]?.error);
          return;
        }
        message.success("Comments added successfully");
        return response;
      } catch (error) {
        message.error(error, "Failed to add comments");
      }
    };

    const commentColumns = [
      "loan_number",
      "comment_date",
      "comment_code",
      "comment_type",
      "dept",
      "comment",
      "comment_2",
      "comment_3",
      "comment_4",
      "comment_5",
      "comment_6",
      "comment_7",
      "comment_8",
      "comment_9",
      "comment_10"
    ]

    useEffect(() => {
      const fetchLoans = async () => {
        try {
          const response = await axios.get(
            `${apiUrl}/loans/loans-mapping-route/${deal_id}`,
          );
          setLoans(response.data);
          setLoading(false);
        } catch (error) {
          message.error("Failed to fetch loans");
          setLoading(false);
        }
      };
      fetchLoans();
    }, [deal_id, apiUrl]);

    
    return (
      <>
      {importModal ? (
      <Import removeModal={() => setImportModal(false)} apiFunction={addComments} columns={commentColumns}/>
      ) : null}
      {loading ? <div>Loading...</div> :
      loans?.length === 0 ? <div style={{paddingLeft: 32}}>No Deal Found</div> : 
      <div className="crumbs" style={{padding: 32}}>
          <Header type="display" size="5">
              Import Servicer Comments
          </Header>
          <Button type="secondary" style={{marginTop: 32}} onClick={() => setImportModal(true)}>
              Import
          </Button>
      </div>}
      </>
    );
  }
  
  export default ServicerComments;