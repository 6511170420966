import React, { useState } from "react";
import { Tabs } from "../../_styleguide";
import { IconChartHistogram, IconReceiptDollar } from "@tabler/icons-react";
import { useNavigate, useParams } from "react-router-dom";
import { Header, Text } from "@centrate-io/barn";
import Dashboard from "./_components/Dashboard/Dashboard";
import LoansTable from "./_components/LoansTable/LoansTable";
import "./SummaryDashboard.scss";

function SummaryDashboard(props) {
  const navigate = useNavigate();
  const params = useParams();
  const [tab, setTab] = useState(params?.tab) || "loans";
  const deal_id = params.id;

  let items = [
    {
      key: "loans",
      label: (
        <div className="tab-name">
          <IconReceiptDollar />
          Loans
        </div>
      ),
      children: <LoansTable />,
    },
    {
      key: "dashboard",
      label: (
        <div className="tab-name">
          <IconChartHistogram />
          Dashboard
        </div>
      ),
      children: <Dashboard />,
    },
  ];

  return (
    <div className="admin-tabs app-summary-dash">
      <div className="admin-header">
        <div className="crumbs">
          <Header type="display" size="5">
            Summary Dashboard
          </Header>
          <Text type="helper">Information on the deals and loans</Text>
        </div>
      </div>
      <Tabs
        onChange={(key) => {
          navigate(`/deal/${deal_id}/summary-dashboard/${key}`);
          setTab(key);
        }}
        activeKey={tab}
        items={items}
        className={tab}
        destroyInactiveTabPane={true}
      />
    </div>
  );
}

export default SummaryDashboard;
