import React, { useState, useEffect } from "react";
import { Link } from "react-router-dom";
import { Search, Table } from "../../../../_styleguide";
import { Button, message } from "@centrate-io/barn";
import { getColumns } from "./Columns";
import axios, { defaultParams } from "../../../../_helpers/axios";
import { exportDeals } from "../../../../_helpers/export";
import { IconTable, IconPlus } from "@tabler/icons-react";
import "./DealsTable.scss";

function DealsTable(props) {
  const [state, setState] = useState({
    loading: true,
    data: [],
    pagination: { pageSize: 10, showSizeChanger: false },
  });
  const [search, setSearch] = useState();
  const [exporting, setExporting] = useState(false);
  const [page, setPage] = useState(1);
  const columns = getColumns();
  const { category } = props;

  // Load Applications
  const fetchDeals = async (params = defaultParams) => {
    try {
      const response = await axios.get(
        `${process.env.REACT_APP_API_URL}/deals`,
        {
          params: {
            ...params,
            search: search,
            category: category || "active",
          },
        },
      );
      const pagination = { ...state.pagination };
      pagination.total = response?.data?.pageTotal;
      pagination.totalAll = response?.data?.total;
      setPage(params?.page || 1);
      props?.setDealCount(pagination.totalAll);
      setState({
        loading: false,
        data: response?.data?.data || [],
        pagination: pagination,
      });
    } catch (error) {
      message.error("Failed to fetch deals");
      setState({
        ...state,
        loading: false,
      });
    }
  };

  const handleTableChange = (pagination, filters) => {
    const pager = { ...state.pagination };
    pager.current = pagination.current;
    setState({ pagination: pager });
    fetchDeals({
      results: pagination.pageSize,
      page: pagination.current,
    });
  };

  const downloadTable = async () => {
    setExporting(true);
    await exportDeals();
    setExporting(false);
  };

  useEffect(() => {
    fetchDeals();
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  const startResults = (page - 1) * defaultParams?.results + 1;
  const endResults = startResults + state?.data?.length - 1;

  return (
    <div>
      <div className="admin-search">
        <Button
          type="secondary"
          size="small"
          className="boxed"
          onClick={downloadTable}
          loading={exporting}
        >
          <IconTable />
          Export
        </Button>
        <Link to="/create-deal">
          <Button size="small" className="boxed">
            <IconPlus /> Create Deal
          </Button>
        </Link>
        <Search
          placeholder={`Search ${category} deals...`}
          value={search}
          onChange={(e) => setSearch(e.target.value)}
          onSearch={() => {
            fetchDeals({ ...defaultParams, search });
          }}
          style={{ width: 304 }}
        />
      </div>
      <div className="admin-table deals-table">
        <Table
          columns={columns}
          loading={state.loading}
          pagination={state.pagination}
          onChange={handleTableChange}
          rowKey={(record) => record.id}
          dataSource={state?.data}
          showSorterTooltip={false}
          scroll={{
            y: "calc(100vh - 247px)",
            x: "max-content",
          }}
          locale={{
            emptyText: state.loading
              ? `Loading ${category} deals...`
              : `No ${category} deals found`,
          }}
        />
      </div>
      {state?.data?.length > 0 && (
        <div className="admin-pagination">
          Showing {startResults}-{endResults} of {state?.pagination?.total || 0}{" "}
        </div>
      )}
    </div>
  );
}

export default DealsTable;
