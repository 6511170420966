import React, { useState, useContext } from "react";
import { Input, Button, message } from "@centrate-io/barn";
import { useNavigate, Link } from "react-router-dom";
import CamLogo from "../../_assets/logos/cam.png";
import { AuthContext } from "../../_contexts/AuthContext";
import "./Register.scss";

function Register(props) {
  const { register } = useContext(AuthContext);
  const navigate = useNavigate();

  const [fullName, setFullName] = useState();
  const [email, setEmail] = useState();
  const [password, setPassword] = useState();
  const [loading, setLoading] = useState(false);

  // Submit login and receive token
  // Submit login and receive token
  const handleSubmit = async () => {
    setLoading(true);
    const registerData = await register(fullName, email, password);
    console.log(registerData);
    if (registerData.success) {
      message.success("Registration successful, please log in");
      navigate("/");
    } else {
      message.error("Invalid login, try again");
    }
    setLoading(false);
  };

  return (
    <React.Fragment>
      <div className="auth-register">
        <div className="auth-info">
          <img className="login-logo" src={CamLogo} alt="PayPathAI" />
        </div>
        <div className="login-form">
          <div className="line">
            <Input.Label>Full name</Input.Label>
            <Input
              value={fullName}
              onChange={(e) => setFullName(e.target.value)}
              size="large"
            />
          </div>

          <div className="line">
            <Input.Label>Email</Input.Label>
            <Input
              value={email}
              onChange={(e) => setEmail(e.target.value)}
              size="large"
            />
          </div>

          <div className="line">
            <Input.Label>Password</Input.Label>
            <Input
              type="password"
              value={password}
              onChange={(e) => setPassword(e.target.value)}
              size="large"
            />
          </div>

          <Button
            loading={loading}
            type="secondary"
            onClick={handleSubmit}
            size="large"
            block
          >
            Create Account
          </Button>
        </div>
      </div>

      <div className="flip-page">
        <Link to="/">
          <Button type="secondary">Login</Button>
        </Link>
      </div>
    </React.Fragment>
  );
}

export default Register;
