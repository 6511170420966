import { Text } from "@centrate-io/barn";
import { Link } from "react-router-dom";
import { Tag } from "../../../../_styleguide";
import { LoanType } from "../../../../_types/loan";
import { formatType } from "../../../../_types/format";
import { IconFile } from "@tabler/icons-react";

export const getColumns = (deal_id) => {
  return [
    {
      title: <div className="column-name">Loan Number</div>,
      dataIndex: "loan_number",
      key: "loan_number",
      width: 220,
      render: (loan_number, data) => (
        <Link
          to={`/deal/${deal_id}/loan/${data.id}`}
          className="application-title"
        >
          <Text className="doc-name">
            <IconFile />
            <b># {loan_number}</b>
          </Text>
        </Link>
      ),
    },
    {
      title: <div className="column-name">Status</div>,
      dataIndex: "loan_status",
      key: "loan_status",
      align: "left",
      width: 120,
      render: (loan_status) => (
        <Tag size="small">{loan_status?.toUpperCase()}</Tag>
      ),
    },
    {
      title: <div className="column-name">Rate</div>,
      dataIndex: "curr_rate",
      key: "curr_rate",
      width: 100,
      align: "center",
      render: (curr_rate) => (
        <Text>{formatType(LoanType["curr_rate"]?.Type, curr_rate)}</Text>
      ),
    },
    {
      title: <div className="column-name">Rate Type</div>,
      dataIndex: "curr_rate_type",
      key: "curr_rate_type",
      align: "left",
      width: 130,
      render: (curr_rate_type) => (
        <Text>
          {formatType(LoanType["curr_rate_type"]?.Type, curr_rate_type)}
        </Text>
      ),
    },
    {
      title: <div className="column-name">UPB</div>,
      dataIndex: "curr_upb",
      key: "curr_upb",
      align: "left",
      width: 150,
      render: (curr_upb) => (
        <Text>{formatType(LoanType["curr_upb"]?.Type, curr_upb)}</Text>
      ),
    },
    {
      title: <div className="column-name">Maturity Date</div>,
      dataIndex: "curr_mat_date",
      key: "curr_mat_date",
      align: "left",
      width: 180,
      render: (curr_mat_date) => (
        <Text>
          {formatType(LoanType["curr_mat_date"]?.Type, curr_mat_date)}
        </Text>
      ),
    },

    {
      title: <div className="column-name">Created</div>,
      dataIndex: "created_at",
      key: "created_at",
      align: "left",
      render: (created_at) => (
        <Text>{formatType(LoanType["created_at"]?.Type, created_at)}</Text>
      ),
    },
  ];
};
