import React, { useState, useEffect } from "react";
import axios from "../../_helpers/axios";
import { Tabs } from "../../_styleguide";
import { IconReport } from "@tabler/icons-react";
import { useNavigate, useParams, Link } from "react-router-dom";
import { Header, Text } from "@centrate-io/barn";
import LoanData from "./_components/LoanData/LoanData";
import Notes from "./_components/Notes/Notes";
import ServicerComments from "./_components/ServicerComments/ServicerComments";
import Collateral from "./_components/Collateral/Collateral";
import CashFlows from "./_components/CashFlows/CashFlows";
import SeniorCredit from "./_components/SeniorCredit/SeniorCredit";
import TitleSecured from "./_components/TitleSecured/TitleSecured";
import ValuesPictures from "./_components/ValuesPictures/ValuesPictures";
import "./Loan.scss";

const Loan = () => {
    const params = useParams();
    const [loan, setLoan] = useState(null);
    const [schema, setSchema] = useState({});
    const apiUrl = process.env.REACT_APP_API_URL;
    const [tab, setTab] = useState(params?.tab) || "";
    const navigate = useNavigate();

    useEffect(() => {
        const fetchLoan = async () => {
            try {
                const response = await axios.get(
                    `${apiUrl}/loans/${params?.loan_id}`,
                );
                setSchema(response.data?.schema || {});
                setLoan(response.data?.loan);
            } catch (error) {
                console.error("Failed to fetch loan", error);
            }
        };
        fetchLoan();
    }, [params?.loan_id, apiUrl]);

    let items = [
        {
            key: "",
            label: (
                <div className="tab-name">
                    <IconReport />
                    Loan Data
                </div>
            ),
            children: <LoanData loan={loan} schema={schema} />,
        },
        {
            key: "notes",
            label: (
                <div className="tab-name">
                    <IconReport />
                    Notes
                </div>
            ),
            children: <Notes loan={loan} />,
        },
        {
            key: "servicer-comments",
            label: (
                <div className="tab-name">
                    <IconReport />
                    Servicer Comments
                </div>
            ),
            children: <ServicerComments />,
        },
        {
            key: "collateral",
            label: (
                <div className="tab-name">
                    <IconReport />
                    Collateral
                </div>
            ),
            children: <Collateral loan={loan} />,
        },
        {
            key: "title-secured",
            label: (
                <div className="tab-name">
                    <IconReport />
                    Title & Secured
                </div>
            ),
            children: <TitleSecured loan={loan} />,
        },
        {
            key: "senior-credit",
            label: (
                <div className="tab-name">
                    <IconReport />
                    Senior & Credit
                </div>
            ),
            children: <SeniorCredit loan={loan} />,
        },
        {
            key: "values-pictures",
            label: (
                <div className="tab-name">
                    <IconReport />
                    Values & Property Pictures
                </div>
            ),
            children: <ValuesPictures loan={loan} />,
        },
        {
            key: "cash-flows",
            label: (
                <div className="tab-name">
                    <IconReport />
                    Cash Flows
                </div>
            ),
            children: <CashFlows loan={loan} />,
        },
    ];

    if (!loan) {
        return <div>Loading...</div>;
    }

    return (
        <div className="admin-tabs app-loan">
            <div className="admin-header">
                <div className="crumbs">
                    <Header type="display" size="5">
                        Loan Number {loan?.loan_number}
                    </Header>
                </div>
            </div>
            <Tabs
                onChange={(key) => {
                    navigate(`/deal/${loan.deal_id}/loan/${loan.id}/${key}`);
                    setTab(key);
                }}
                activeKey={tab}
                items={items}
                className={tab}
                destroyInactiveTabPane={true}
            />
        </div>
    );
};

export default Loan;
