export const getErrors = error => {
  if (error?.response?.data?.error) {
    return error?.response?.data?.error;
  } else if (error && error.message) {
    return error.message.replace('GraphQL error: ', '');
  } else if (error && error.graphQLErrors && error.graphQLErrors.length > 0) {
    return error.graphQLErrors[0].message.replace('GraphQL error: ', '');
  } else {
    return null;
  }
};