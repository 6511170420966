import React, { useState, useEffect, useContext } from "react";
import { TextArea, AvatarName } from "../../../../_styleguide";
import axios from "../../../../_helpers/axios";
import { AuthContext } from "../../../../_contexts/AuthContext";
import { Button, message } from "@centrate-io/barn";
import moment from "moment";
import "./Notes.scss";

const Note = (props) => {
  const { note } = props;
  const { user } = note;
  return (
    <div className="application-note" id={"note-" + note.id}>
      <div className="note-header">
        <AvatarName tooltip={`${user?.full_name}`}>
          {user?.full_name?.[0]}
        </AvatarName>
        <div className="note-user">{user?.full_name}</div>
        <span>{moment(note.created_at).format("MMMM Do, hh:mm a")}</span>
      </div>
      <div className="note-body">
        <p>{note.content}</p>
      </div>
    </div>
  );
};

function Notes(props) {
  const { auth } = useContext(AuthContext);
  const [loading, setLoading] = useState(true);
  const [saving, setSaving] = useState(false);
  const [note, setNote] = useState();
  const [notes, setNotes] = useState([]);
  const user = auth?.user;

  // Load Entries
  const fetchNotes = async () => {
    try {
      const response = await axios.get(
        `${process.env.REACT_APP_API_URL}/notes/${props?.loan?.id}`,
      );
      setNotes(response.data);
    } catch (error) {
      message.error("Failed to fetch notes: " + error);
    }
    setLoading(false);
  };

  useEffect(() => {
    fetchNotes();
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  const createNote = async () => {
    setSaving(true);
    try {
      const response = await axios.post(
        `${process.env.REACT_APP_API_URL}/notes/add`,
        {
          loan_id: props?.loan?.id,
          user_id: user.id,
          content: note,
        },
      );
      if (response?.data?.success === true) {
        message.success("Note successfully created");
      } else {
        throw new Error("Error creating note, try again");
      }
      fetchNotes();
      setNote(undefined);
      setSaving(false);
    } catch (err) {
      console.log(err);
      message.error("Error creating note, try again");
      setSaving(false);
    }
  };

  const createDates = (notes) => {
    let dates = [];
    notes.forEach((data) => {
      dates.push(moment(data.created_at).format("dddd, MMMM Do YYYY"));
    });
    return [...new Set(dates.reverse())];
  };

  let dateList = null;
  if (notes?.length > 0) {
    const dates = createDates(notes);
    dateList = dates.map((item, i) => {
      let notesList = notes.reverse().map((n) => {
        if (moment(n.created_at).format("dddd, MMMM Do YYYY") === item) {
          return <Note key={n.id} note={n} />;
        }
        return null;
      });
      return (
        <div key={"notegroup-" + i} date={item} className="date-section">
          <div className="note-date">
            <span>
              {item === moment().format("dddd, MMMM Do YYYY") ? "Today" : item}
            </span>
          </div>
          {notesList}
        </div>
      );
    });
  }

  return loading ? (
    <div>Loading...</div>
  ) : (
    <div className="admin-application-notes">
      <div className="note-compose">
        <TextArea
          disabled={saving}
          value={note}
          onChange={(e) => setNote(e.target.value)}
          size="large"
          autoSize={true}
          placeholder="Start your note..."
        />
        <Button
          disabled={!note || note?.length < 1}
          loading={saving}
          type="primary"
          size="small"
          onClick={() => createNote()}
        >
          Add Note
        </Button>
      </div>
      <div className="note-content">
        {notes.length > 0 ? dateList : "No notes yet"}
      </div>
    </div>
  );
}

export default Notes;
