import React, { Component } from "react";
import { Tag as AntdTag } from "antd";
import "./Tag.scss";

class Tag extends Component {
  render() {
    const props = { ...this.props };
    props.className = "pp-tag " + (props.className || "");

    if (props.size) {
      props.className += " pp-tag-" + props.size;
    }

    if (props.type) {
      props.className += " pp-tag-" + props.type;
    }

    return <AntdTag {...props} />;
  }
}

export default Tag;
