import { Text } from "@centrate-io/barn";
import { Link } from "react-router-dom";

export const getColumns = (category) => {
  return [
    {
      title: (
        <div className="column-name">
          Sample Col
        </div>
      ),
      dataIndex: "sample_col",
      key: "sample_col",
      width: 280,
      render: (name, data) => (
        
        <Text className="doc-name">
          <b>Sample Col</b>
        </Text>
       
      ),
    },
    {
      title: (
        <div className="column-name">
          Sample Col 2
        </div>
      ),
      dataIndex: "sample_col_2",
      key: "sample_col_2",
      width: 280,
      render: (name, data) => (
        
        <Text className="doc-name">
          <b>Sample Col 2</b>
        </Text>
       
      ),
    },
    {
      title: (
        <div className="column-name">
          Sample Col 3
        </div>
      ),
      dataIndex: "sample_col_3",
      key: "sample_col_3",
      width: 280,
      render: (name, data) => (
        
        <Text className="doc-name">
          <b>Sample Col 3</b>
        </Text>
       
      ),
    }
  ];
};
