import React, { useState } from "react";
import { Header, Text } from "@centrate-io/barn";
import { useParams } from "react-router-dom";

function Dashboard(props) {
  const params = useParams();
  const deal_id = params?.id;
  return (
    <div className="crumbs" style={{ padding: 32 }}>
      <Header type="display" size="5">
        Charts go here
      </Header>
      <Text type="helper"></Text>
    </div>
  );
}

export default Dashboard;
